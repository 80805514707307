const INITIAL_STATE = {
  workflowList: null,
  generalWorkflowList: null,
  workflowDetails: null,
  searchDetails: null,
  searchState: false,
  sideNav: false,
  actionModalState: {},
  error: false,
  actionNodeDetails: {},
};
const WorkflowDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_WORKFLOW_LIST":
      return {
        ...state,
        workflowList: action.payload,
      };

    case "SET_SIDE_NAV_STATUS":
      return {
        ...state,
        sideNav: action.payload,
      };

    case "SET_WORKFLOW_DETALS":
      return {
        ...state,
        workflowDetails: action.payload,
      };
    case "SET_ACTION_NODE_DETAILS":
      return {
        ...state,
        actionNodeDetails: action.payload,
      };
    case "SET_ACTION_NODE_MODAL":
      return {
        ...state,
        actionModalState: action.payload,
      };
    case "SET_GENERAL_WORKFLOW_LIST":
      return {
        ...state,
        generalWorkflowList: action.payload,
      };
    case "SET_SEARCH_DETALS":
      return {
        ...state,
        searchDetails: action.payload,
      };
    case "SET_SEARCH_STATE":
      return {
        ...state,
        searchState: action.payload,
      };
    case "SET_ERROR_DETAILS":
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default WorkflowDetailReducer;
