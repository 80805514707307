import React from "react";
import { Menu, MenuItem } from "components/toolkit";
import SuiTypography from "components/toolkit/SuiTypography";
import SuiBox from "components/toolkit/SuiBox";

export const MenuOptionsComponent = ({
    open,
    id,
    anchorEl,
    handleClose,
    handleEdit,
    handleModalOpen,
    view,
}) => {
    return (
        <Menu
            id='long-menu'
            MenuListProps={{
                "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {view ? (
                <MenuItem onClick={handleEdit} key='view'>
                    {"view"}
                </MenuItem>
            ) : (
                [
                    <MenuItem
                        onClick={(event) => handleEdit(event, id)}
                        key='edit'
                    >
                        {"Edit"}
                    </MenuItem>,
                    <MenuItem
                        onClick={(event) => {
                            handleModalOpen(event, id);
                            handleClose(event);
                        }}
                        key='delete'
                    >
                        {"Delete"}
                    </MenuItem>,
                ]
            )}
        </Menu>
    );
};

export const ViewOptionsComponent = ({
    open,
    id,
    anchorEl,
    handleClose,
    handleView,
    handleModalOpen,
    token,
}) => {
    return (
        <Menu
            id='long-menu'
            MenuListProps={{
                "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={() => handleView(id, token)}>{"View"}</MenuItem>
            <MenuItem
                onClick={(event) => {
                    handleModalOpen(event, id);
                    handleClose(event);
                }}
            >
                {"Delete"}
            </MenuItem>
        </Menu>
    );
};

export const CoursesOptionsComponent = ({
    open,
    id,
    anchorEl,
    handleClose,
    handleEdit,
    handleModalOpen,
}) => {
    return (
        <Menu
            id='long-menu'
            MenuListProps={{
                "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={(event) => handleEdit(event, id)}>
                {"Edit"}
            </MenuItem>
            <MenuItem
                onClick={(event) => {
                    handleModalOpen(event, id);
                    handleClose(event);
                }}
            >
                {"Delete"}
            </MenuItem>
        </Menu>
    );
};
export const RoleOptionsComponent = ({
    open,
    anchorEl,
    handleClose,
    roleList,
    handleRoleSelect,
    individualTymelineDetails,
}) => {
    return (
        <Menu
            id='long-menu'
            MenuListProps={{
                "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <SuiBox pl={2} py={1}>
                <SuiTypography
                    variant='button'
                    fontWeight='medium'
                    textColor='dark'
                >
                    Select job role for comparison
                </SuiTypography>
            </SuiBox>
            {roleList?.roles?.map((role) => {
                return (
                    <MenuItem
                        key={role?.role_id}
                        onClick={() => {
                            handleClose();
                            handleRoleSelect(
                                role?.description,
                                individualTymelineDetails,
                                role?.role_title
                            );
                        }}
                    >
                        {role?.role_title}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

export const OptionsComponent = ({
    open,
    anchorEl,
    handleClose,
    optionsList,
    handleOptionSelect,
}) => {
    return (
        <Menu
            id='long-menu'
            MenuListProps={{
                "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <SuiBox pl={2} py={1}>
                <SuiTypography
                    variant='button'
                    fontWeight='medium'
                    textColor='dark'
                >
                    Select team for comparison
                </SuiTypography>
            </SuiBox>
            {optionsList?.map((option) => {
                return (
                    <MenuItem
                        key={option?.id}
                        onClick={() => {
                            handleClose();
                            handleOptionSelect(option?.id, option?.title);
                        }}
                    >
                        {option?.title}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};
