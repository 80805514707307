import React from "react";
import SuiBox from "components/toolkit/SuiBox";
import ProfileHeader from "./ProfileHeader";
import { Typography, Grid, Card, Button } from "components/toolkit";
import RecordItem from "./RecordItem";

function PublicLinkComponent() {
  return (
    <SuiBox mx={10} my={6}>
      <ProfileHeader />
      <SuiBox mt={3} display="flex" justifyContent="center">
        <Typography variant="h5" textColor="text" fontWeight="medium">
          Records
        </Typography>
      </SuiBox>
      <Grid container spacing={3} mt={0.5}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backdropFilter: `saturate(200%) blur(30px)`,
              backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                rgba(white.main, 0.2),
              boxShadow: ({ boxShadows: { navbarBoxShadow } }) =>
                navbarBoxShadow,
            }}
            style={{ marginBottom: "16px" }}
          >
            <SuiBox px={2} pt={1}>
              <RecordItem
                mb={1}
                text="Joined Google India as software developer on 29 Aug 2020"
                logo={
                  "https://1000logos.net/wp-content/uploads/2016/11/google-logo.jpg"
                }
                location="Banglore, Karnataka"
              />
              <RecordItem
                mb={1}
                text="Worked as contributer in plugin named react-native-share between 2020-2022"
                logo={
                  "https://logos-download.com/wp-content/uploads/2016/09/React_logo_wordmark.png"
                }
                location="Delhi, India"
              />
              <RecordItem
                mb={1}
                text="Joined facebook India as software developer on 29 Aug 2020"
                logo={
                  "https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-Meaning.jpg"
                }
                location="Hyderabad, India"
              />
            </SuiBox>
            <SuiBox display="flex" justifyContent="center" py={2}>
              <Button
                size="small"
                buttonColor="primary"
                variant="gradient"
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  height: "1.5rem",
                  fontSize: "10px",
                }}
              >
                Login to see all records
              </Button>
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backdropFilter: `saturate(200%) blur(30px)`,
              backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                rgba(white.main, 0.2),
              boxShadow: ({ boxShadows: { navbarBoxShadow } }) =>
                navbarBoxShadow,
            }}
          >
            <SuiBox px={2} pt={1}>
              <RecordItem
                mb={1}
                text="Completed Higher education (12th) from D.A.V mayur vihar, Delhi in 2015"
                logo={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_FZ6-xtOMXUuj7DrBA3Uix7RKzM3HJchY0pbfn7Q&s"
                }
                location="Delhi, India"
              />
              <RecordItem
                mb={1}
                text="Completed engineering from NSIT Delhi, with CGPA of 7.0 in 2021"
                logo={
                  "https://upload.wikimedia.org/wikipedia/commons/e/e9/University_of_delhi_logo.png"
                }
                location="Delhi, India"
              />
              <RecordItem
                mb={1}
                text="Did Master's in Cosmology from Delhi university in 2022"
                logo={
                  "https://upload.wikimedia.org/wikipedia/commons/e/e9/University_of_delhi_logo.png"
                }
                location="Delhi, India"
              />
            </SuiBox>
            <SuiBox display="flex" justifyContent="center" py={2}>
              <Button
                size="small"
                buttonColor="primary"
                variant="gradient"
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  height: "1.5rem",
                  fontSize: "10px",
                }}
              >
                Login to see all records
              </Button>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default PublicLinkComponent;
