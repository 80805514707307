export const TableLabels = {
  dashboard: {
    name: "Name",
    email: "Email",
    role: "Role",
    workflow: "Onboarding Workflow",
    fields: "Fields",
    expiryDays: "Link Expires In",
    status: "Status",
  },
  role: {
    name: "Role",
  },
  orientation: {
    title: "Title",
    no_of_questions: "No of questions",
  },
  training: {
    title: "Sub Menu Title",
    name: "Name",
    role: "Role",
    date: "Date",
    questions: "Question Duration",
    no_of_questions: "No of Questions",
  },
  users: {
    name: "Name",
    email: "email",
    role: "Role",
  },
  company_policy: {
    title: "Company Policy Title",
    type: "Type",
  },
  compensation: {
    title: "Title",
    type: "Type",
  },
  issue: {
    title: "Issue Letters Title",
    type: "Type",
  },
  workflow: {
    title: "Workflow Title",
    type: "Workflow Type",
    public_link: "Public Link",
    status: "Status of public link",
  },
  teams: {
    name: "Name of Team",
    no_of_questions: "No of members"
  },
};
