
const INITIAL_STATE = {
    rolesList: null,
    role: null,
    skills: null,
    filterdRoleLists:null,
    error: null,
    redirect: false,
    show: false,
}
const RoleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_ROLE_LIST':
            return {
                ...state,
                rolesList: action.payload
            }
        case 'SET_ROLE':
            return {
                ...state,
                role: action.payload
            }
        case 'SET_SKILLS':
            return {
                ...state,
                skills: action.payload
            }
        
        case 'SET_FILTERED_ROLE_LISTS':
            return {
                ...state,
                filterdRoleLists: action.payload
            }
        case 'SET_ERROR_DETAILS':
            return {
                ...state,
                error: action.payload
            }

        case 'SHOW_MODAL':
            return {
                ...state,
                show: action.payload
            }

        case 'SET_REDIRECT':
            return {
                ...state,
                redirect: action.payload
            }

        default:
            return state
    }
}

export default RoleReducer
