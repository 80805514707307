import firebase from "firebase/app";
import "firebase/storage";
import "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyAAgvv-E__zV_N0juNVfa7BXZnAiczRKrk",
  authDomain: "talkona.firebaseapp.com",
  databaseURL: "https://talkona-default-rtdb.firebaseio.com",
  projectId: "talkona",
  storageBucket: "talkona.appspot.com",
  messagingSenderId: "722725268265",
  appId: "1:722725268265:web:ef1faf17886d3bf5ea6538",
  measurementId: "G-BRT80S918N",
};

// firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 5000;

export { remoteConfig, storage, firebase as default };
