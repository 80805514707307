/* eslint-disable import/no-anonymous-default-export */
/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { blue } from "@mui/material/colors";

/**
 * The base colors for the Soft UI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard PRO Material using thie file.
 */

export default {
    background: {
        default: "#f8f9fa",
    },

    text: {
        main: "#67748e",
        focus: "#67748e",
    },

    transparent: {
        main: "transparent",
    },

    white: {
        main: "#ffffff",
        focus: "#ffffff",
    },

    black: {
        light: "#141414",
        main: "#000000",
        focus: "#000000",
    },

    primary: {
        main: "#cb0c9f",
        focus: "#ad0a87",
    },

    secondary: {
        main: "#8392ab",
        focus: "#96a2b8",
    },

    link: {
        main: blue[900],
        focus: blue[700],
    },

    accent: {
        main: "#4457fe",
        focus: "#4457fe",
    },

    aimagic: {
        main: "#9f1bef",
        focus: "#9f1bef",
    },

    info: {
        main: "#17c1e8",
        focus: "#3acaeb",
    },

    success: {
        main: "#66bb6a",
        focus: "#95dc39",
    },

    warning: {
        main: "#fbcf33",
        focus: "#fcd652",
    },

    error: {
        main: "#ea0606",
        focus: "#c70505",
    },
    light: {
        main: "#e9ecef",
    },
    current_plan: {
        main: "#e9ecef",
        focus: "#344767",
    },
    better_plan: {
        main: "#17c1e8",
        focus: "#17c1e8",
    },
    best_plan: {
        main: "#66bb6a",
        focus: "#66bb6a",
    },
    dark: {
        main: "#344767",
        focus: "#344767",
    },
    tempColor1: {
        main: "#0af2c8",
    },
    tempColor2: {
        main: "#E899DC",
    },
    tempColor3: {
        main: "#ADC178",
    },
    tempColor4: {
        main: "#03045E",
    },
    tempColor5: {
        main: "#FFD60A",
    },
    tempColor6: {
        main: "#5F0F40",
    },
    tempColor7: {
        main: "#6E44FF",
    },
    tempColor8: {
        main: "#FB6107",
    },
    tempColor9: {
        main: "#17ad37",
    },

    grey: {
        100: "#f8f9fa",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#6c757d",
        700: "#495057",
        800: "#343a40",
        900: "#212529",
    },

    gradients: {
        primary: {
            main: "#7928ca",
            state: "#ff0080",
        },

        secondary: {
            main: "#627594",
            state: "#a8b8d8",
        },

        info: {
            main: "#2152ff",
            state: "#21d4fd",
        },

        success: {
            main: "#17ad37",
            state: "#98ec2d",
        },

        warning: {
            main: "#f53939",
            state: "#fbcf33",
        },

        error: {
            main: "#ea0606",
            state: "#ff667c",
        },

        light: {
            main: "#ced4da",
            state: "#ebeff4",
        },

        dark: {
            main: "#141727",
            state: "#3a416f",
        },
        tempColor1: {
            main: "#0af2c8",
            state: "#0af2c8",
        },
        tempColor2: {
            main: "#E899DC",
            state: "#E899DC",
        },
        tempColor3: {
            main: "#ADC178",
            state: "#ADC178",
        },
        tempColor4: {
            main: "#03045E",
            state: "#03045E",
        },
        tempColor5: {
            main: "#FFD60A",
            state: "#FFD60A",
        },
        tempColor6: {
            main: "#5F0F40",
            state: "#5F0F40",
        },
        tempColor7: {
            main: "#6E44FF",
            state: "#6E44FF",
        },
        tempColor8: {
            main: "#FB6107",
            state: "#FB6107",
        },
        tempColor9: {
            main: "#17ad37",
            state: "#17ad37",
        },
        current_plan: {
            main: "#627594",
            state: "#a8b8d8",
        },

        better_plan: {
            main: "#2152ff",
            state: "#21d4fd",
        },

        best_plan: {
            main: "#17ad37",
            state: "#98ec2d",
        },
    },

    socialMediaColors: {
        facebook: {
            main: "#3b5998",
            dark: "#344e86",
        },

        twitter: {
            main: "#55acee",
            dark: "#3ea1ec",
        },

        instagram: {
            main: "#125688",
            dark: "#0e456d",
        },

        linkedin: {
            main: "#0077b5",
            dark: "#00669c",
        },

        pinterest: {
            main: "#cc2127",
            dark: "#b21d22",
        },

        youtube: {
            main: "#e52d27",
            dark: "#d41f1a",
        },

        vimeo: {
            main: "#1ab7ea",
            dark: "#13a3d2",
        },

        slack: {
            main: "#3aaf85",
            dark: "#329874",
        },

        dribbble: {
            main: "#ea4c89",
            dark: "#e73177",
        },

        github: {
            main: "#24292e",
            dark: "#171a1d",
        },

        reddit: {
            main: "#ff4500",
            dark: "#e03d00",
        },

        tumblr: {
            main: "#35465c",
            dark: "#2a3749",
        },
    },

    alertColors: {
        primary: {
            main: "#7928ca",
            state: "#d6006c",
            border: "#efb6e2",
        },

        secondary: {
            main: "#627594",
            state: "#8ca1cb",
            border: "#dadee6",
        },

        info: {
            main: "#2152ff",
            state: "#02c6f3",
            border: "#b9ecf8",
        },

        success: {
            main: "#17ad37",
            state: "#84dc14",
            border: "#daf3b9",
        },

        warning: {
            main: "#f53939",
            state: "#fac60b",
            border: "#fef1c2",
        },

        error: {
            main: "#ea0606",
            state: "#ff3d59",
            border: "#f9b4b4",
        },

        light: {
            main: "#ced4da",
            state: "#d1dae6",
            border: "#f8f9fa",
        },

        dark: {
            main: "#141727",
            state: "#2c3154",
            border: "#c2c8d1",
        },
    },

    badgeColors: {
        primary: {
            background: "#f883dd",
            text: "#a3017e",
        },

        secondary: {
            background: "#e4e8ed",
            text: "#5974a2",
        },

        info: {
            background: "#abe9f7",
            text: "#08a1c4",
        },

        success: {
            background: "#cdf59b",
            text: "#67b108",
        },

        warning: {
            background: "#fef5d3",
            text: "#fbc400",
        },

        error: {
            background: "#fc9797",
            text: "#bd0000",
        },

        light: {
            background: "#ffffff",
            text: "#c7d3de",
        },

        dark: {
            background: "#8097bf",
            text: "#1e2e4a",
        },
        current_plan: {
            background: "#e4e8ed",
            text: "#5974a2",
        },

        better_plan: {
            background: "#abe9f7",
            text: "#08a1c4",
        },
        best_plan: {
            background: "#cdf59b",
            text: "#67b108",
        },
    },

    inputColors: {
        borderColor: { main: "#d2d6da", focus: "#35d1f5" },
        boxShadow: "#81e3f9",
        error: "#fd5c70",
        success: "#66d432",
    },

    sliderColors: {
        thumb: { borderColor: "#d9d9d9" },
    },

    circleSliderColors: {
        background: "#d3d3d3",
    },

    tabs: {
        indicator: { boxShadow: "#ddd" },
    },
};
