const INITIAL_STATE = {
    teamList: null,
    filterTeamsList: null,
    team: null,
    error: null,
    show: false,
    teamRecommendation: null,
    teamHealth: null,
    teamGoals: null,
    organizationGoals: null,
    individualOrganizationGoal: null,
    teamGoal: null,
    teamMembersGoal: null,
    dashboardTeamMembersGoal: null,
    teamMemberGoal: null,
};
const TeamsDetailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_TEAMS_LIST":
            return {
                ...state,
                teamList: action.payload,
            };
        case "SET_ORGANIZATION_GOALS":
            return {
                ...state,
                organizationGoals: action.payload,
            };

        case "SET_TEAM_MEMBERS_GOALS":
            return {
                ...state,
                teamMembersGoal: action.payload,
            };
        case "SET_DASHBOARD_TEAM_MEMBERS_GOAL":
            return {
                ...state,
                dashboardTeamMembersGoal: action.payload,
            };
        case "SET_TEAM_MEMBER_GOALS":
            return {
                ...state,
                teamMembersGoal: action.payload,
            };
        case "SET_TEAM_MEMBER_GOAL":
            return {
                ...state,
                teamMemberGoal: action.payload,
            };
        case "SET_INDIVIDUAL_ORGANIZATION_GOAL":
            return {
                ...state,
                individualOrganizationGoal: action.payload,
            };
        case "SET_FILTER_TEAMS_LIST":
            return {
                ...state,
                filterTeamsList: action.payload,
            };
        case "SET_TEAM":
            return {
                ...state,
                team: action.payload,
            };
        case "SET_ERROR_DETAILS":
            return {
                ...state,
                error: action.payload,
            };
        case "SET_TEAM_RECOMMENDATIONS":
            return {
                ...state,
                teamRecommendation: action.payload,
            };
        case "SET_TEAM_HEALTH":
            return {
                ...state,
                teamHealth: action.payload,
            };

        case "SHOW_MODAL":
            return {
                ...state,
                show: action.payload,
            };

        case "SET_TEAM_GOALS":
            return {
                ...state,
                teamGoals: action.payload,
            };
        case "SET_TEAM_GOAL":
            return {
                ...state,
                teamGoal: action.payload,
            };

        default:
            return state;
    }
};

export default TeamsDetailReducer;
