import SuiTypography from "../SuiTypography";

export const stepsData = [
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        See overall status of available verification requests and validation
        requests.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".tymeline_dashboard",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Dashboard
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Create new users and check already added users.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".tymeline_users",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Users
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Check candidate tymeline, buy credits and much more.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".check_tymeline",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Check Tymeline
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Add professional records of candidate.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".add_record",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Add Record
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Nominate a candidate for quick verification.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".nominate_candidate",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Nominate Candidate
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Check available verification requests raised against company.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".verification_requests",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Verification Requests
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Validate the available verified requests. Only validated records goes to
        the tymeline.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".validate_pool",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Validate Pool
      </SuiTypography>
    ),
  },
  {
    content: (
      <SuiTypography variant="h6" fontWeight="regular">
        Give permissions to users who can add records and validate requests.
      </SuiTypography>
    ),

    placement: "bottom",
    target: ".tymeline_settings",
    disableBeacon: true,
    title: (
      <SuiTypography variant="h5" fontWeight="medium">
        Settings
      </SuiTypography>
    ),
  },
];
