import React from "react";
// import WorkIcon from "@mui/icons-material/Work";

// const VacancyPage = React.lazy(() => import("pages/createVacancy/VacancyPage"));
const MyVacanciesPage = React.lazy(() =>
  import("pages/createVacancy/MyVacanciesPage")
);
const IndividualVacancyDisplay = React.lazy(() =>
  import("pages/createVacancy/IndividualVacancyDisplay")
);

const IndividualJobApplicationPage = React.lazy(() =>
  import("pages/createVacancy/IndividualJobApplicationPage")
);

const routes = [
  // {
  //   path: "/vacancies",
  //   name: "Vacancies",
  //   type: "collapse",
  //   component: VacancyPage,
  //   icon: <WorkIcon size="12px" />,
  //   exact: true,
  //   showInMenu: true,
  // },
  {
    path: "/my-vacancies",
    type: "collapse",
    component: MyVacanciesPage,
    exact: true,
  },
  {
    path: "/view-vacancy/:id",
    type: "collapse",
    component: IndividualVacancyDisplay,
    exact: true,
  },
  {
    path: "/view-job-application/:id",
    type: "collapse",
    component: IndividualJobApplicationPage,
    exact: true,
  },
];

export default routes;
