export const ButtonLabels = {
  dashboard: {
    startRecruit: {
      label: "Start Recruiting",
    },
    view: {
      label: "View Candidates",
    },
    recruit: {
      label: "Recruit Candidate",
      type: "submit",
    },
    start_onboard: {
      label: "Start Onboarding",
      type: "button",
    },
  },
  candidate: {
    hold: {
      label: "Hold",
      type: "submit",
    },
    reject: {
      label: "Reject",
      type: "submit",
    },
    approve: {
      label: "Approve",
      type: "submit",
    },
    select: {
      label: "Select",
      type: "submit",
    },
    update: {
      label: "Update",
      type: "submit",
    },
  },
  general: {
    create: {
      label: "Create",
      type: "submit",
    },
    add: {
      label: "Add new",
      type: "submit",
    },
    submit: {
      label: "Finish",
      type: "submit",
    },
    edit: {
      label: "Update",
      type: "submit",
    },
    cancel: {
      label: "Cancel",
    },
    proceed: {
      label: "Proceed",
    },
    question: {
      add: "Add questions",
    },
    close: {
      label: "Ok",
    },
  },
  screener: {
    create: {
      label: "Add Screener",
    },
    view: {
      label: "View Screeners",
    },
  },
  test: {
    create: {
      label: "Add Test",
    },
  },
};
