import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";

// import logger from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./root-reducer";

const middlewares = [thunk];

// if (process.env.REACT_APP_ENABLE_REDUX_LOGGING) {
//   middlewares.push(logger);
// }

const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

// TODO: For prod build do not compose with dev tools.
export const store = createStore(rootReducer, composedEnhancers);

export const persistor = persistStore(store);
