import React from "react";
import { Route, Switch } from "react-router-dom";
import unAuthRoutesConfig from "./unAuthRoutesConfig";

const UnAuthRoutes = () => {
  return (
    <Switch>
      <React.Suspense fallback={""}>
        {unAuthRoutesConfig.map((routeConfig, index) => (
          <Route {...routeConfig} key={index} />
        ))}
      </React.Suspense>
    </Switch>
  );
};

export default UnAuthRoutes;
