import React from "react";
import baseTheme from "../assets/theme";
import { ThemeProvider } from "@mui/material/styles";
import { selectCompanyPrimaryColour } from "selectors/company";
import { selectCompanySecondaryColour } from "selectors/company";
import { useSelector } from "react-redux";

const useThemeBuilder = () => {
  let theme = { ...baseTheme };
  const { palette } = baseTheme;
  const companyPrimary = useSelector(selectCompanyPrimaryColour);
  const companySecondary = useSelector(selectCompanySecondaryColour);
  theme.palette = {
    ...palette,
    primary: {
      main: companyPrimary[0] ?? palette.primary.main,
      focus: companyPrimary[1] ?? palette.primary.focus,
    },
    // secondary: {
    //     main: companySecondary[0] ?? palette.secondary.main,
    //     focus: companySecondary[1] ?? palette.secondary.focus,
    // },
    gradients: {
      ...palette.gradients,
      primary: {
        main: companyPrimary[0] ?? palette.gradients.primary.main,
        state: companyPrimary[1] ?? palette.gradients.primary.state,
      },
      secondary: {
        main: companySecondary[0] ?? palette.gradients.secondary.main,
        state: companySecondary[1] ?? palette.gradients.secondary.state,
      },
    },
  };
  return theme;
};

const KwicklyThemeProvider = ({ children }) => {
  const theme = useThemeBuilder();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default KwicklyThemeProvider;
