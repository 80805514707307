// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";

function Goals({ color, size }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>Goals</title>
            <g id="Basic-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Rounded-Icons"
                    transform="translate(-1870.000000, -591.000000)"
                >
                    <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
                        <g id="document" transform="translate(154.000000, 300.000000)">
                            <path
                                d="M13.6 7.06492V4.36492L16.3 1.66492L17.2 3.46492L19 4.36492L16.3 7.06492H13.6ZM13.6 7.06492L9.99997 10.6649M19 10.6649C19 15.6355 14.9706 19.6649 10 19.6649C5.02944 19.6649 1 15.6355 1 10.6649C1 5.69435 5.02944 1.66492 10 1.66492M14.5 10.6649C14.5 13.1502 12.4853 15.1649 10 15.1649C7.51472 15.1649 5.5 13.1502 5.5 10.6649C5.5 8.17964 7.51472 6.16492 10 6.16492"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

// Setting default values for the props of Document
Goals.defaultProps = {
    color: "dark",
    size: "16px",
};

// Typechecking props for the Document
Goals.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "white",
    ]),
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Goals;