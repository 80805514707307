import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Radio,
  FormControl,
} from "components/toolkit";

const SliderComponent = ({ surveysListOld }) => {
  const [current, setCurrent] = useState(0);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [finalValues, setFinalValues] = React.useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue(value);
    const updateFinalValues = [...finalValues, { [name]: value }];
    setFinalValues(updateFinalValues);
    // nextSlide();
  };
  const handleClick = (name, value) => {
    setSelectedValue(value);
    const updateFinalValues = [...finalValues, { [name]: value }];
    setFinalValues(updateFinalValues);
    // nextSlide();
  };
  const surveysList = [
    {
      id: 1,
      title: "How are you feeling about the team that you are working",
      optionDetails: {
        name: "question1",
        options: ["Not good", "Average", "Good", "Excellent"],
      },
    },
    {
      id: 2,
      title: "Is your team aligning with your goals?",
      optionDetails: {
        name: "question2",
        options: ["Not good", "Average", "Good", "Excellent"],
      },
    },
    {
      id: 3,
      title: "Are you happy with your team lead?",
      optionDetails: {
        name: "question3",
        options: ["Not good", "Average", "Good", "Excellent"],
      },
    },
  ];
  const prevSlide = () => {
    const updatedCurrentValue =
      current === 0 ? surveysList.length - 1 : current - 1;
    setCurrent(updatedCurrentValue);
  };

  const nextSlide = () => {
    const updatedCurrentValue =
      current === surveysList.length - 1 ? 0 : current + 1;
    setCurrent(updatedCurrentValue);
    setSelectedValue("a");
  };
  const getClassName = (number) => {
    return current === number ? "disabled-class" : "";
  };
  // console.log(finalValues);
  return (
    <div style={{ position: "relative" }}>
      {surveysList.map((card, index) => {
        return (
          <Box key={card.id}>
            {index === current && (
              <Card
                className={
                  index === current
                    ? "animated-card fade-in-right"
                    : "animated-card"
                }
                style={{
                  animationDirection:
                    index > current ? "fade-in-left" : "fade-in-right",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      whiteSpace: "pre-line",
                      display: "block",
                      fontSize: 16,
                      lineHeight: 1.3,
                    }}
                    variant="label"
                  >
                    {card.title}
                  </Typography>
                  <Box>
                    <FormControl>
                      {card.optionDetails.options.map((item, index) => (
                        <Box display="flex" key={index} mt={2}>
                          <Radio
                            checked={selectedValue === item}
                            onChange={handleChange}
                            value={item}
                            name={card.optionDetails.name}
                            inputProps={{ "aria-label": item }}
                          />
                          <Typography
                            onClick={() =>
                              handleClick(card.optionDetails.name, item)
                            }
                            sx={{
                              fontSize: 16,
                              lineHeight: 1.3,
                              cursor: "pointer",
                            }}
                          >
                            {item}
                          </Typography>
                        </Box>
                      ))}
                    </FormControl>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>
        );
      })}
      <Box className="navigation-icon-container">
        <KeyboardArrowLeftIcon
          className={getClassName(0)}
          onClick={prevSlide}
        />
        <KeyboardArrowRightIcon
          className={getClassName(surveysList?.length - 1)}
          onClick={nextSlide}
        />
      </Box>
    </div>
  );
};

export default SliderComponent;
