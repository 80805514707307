const INITIAL_STATE = {
  company_details: null,
  basic_details: null,
  error: null,
};
const CompanyDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_COMPANY_DETAILS":
      return {
        ...state,
        company_details: action.payload,
      };
    case "SET_COMPANY_BASIC_DETAILS":
      return {
        ...state,
        basic_details: action.payload,
      };
    case "SET_ERROR_DETAILS":
      return {
        ...state,
        error: action.payload,
      };

    case "CLEAR_COMPANY_DETAILS":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default CompanyDetailReducer;
