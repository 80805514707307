import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/toolkit/SuiBox";

// Custom styles for the SidenavCollapse
import styles from "components/toolkit/Sidenav/styles/sidenavCollapse";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";
import React from "react";
import { Tooltip } from "@mui/material";

const SidenavLock = React.forwardRef(
    (
        { icon, name, children, className, active, noCollapse, open, ...rest },
        ref
    ) => {
        const [controller] = useSoftUIController();
        const { miniSidenav, transparentSidenav, sidenavColor } = controller;

        const classes = styles({
            active,
            noCollapse,
            open,
            miniSidenav,
            transparentSidenav,
            sidenavColor,
        });
        return (
            <Tooltip
                title="Your role doesn't have access to this section. Please contact your admin to grant access."
                placement='top'>
                <ListItem className={className} component='li' ref={ref}>
                    <SuiBox {...rest} customClass={classes.collapse_item}>
                        <ListItemIcon className={classes.collapse_iconBox}>
                            {typeof icon === "string" ? (
                                <Icon className={classes.collapse_icon}>
                                    {icon}
                                </Icon>
                            ) : (
                                icon
                            )}
                        </ListItemIcon>

                        <ListItemText
                            primary={name}
                            classes={{ root: classes.collapse_text }}
                        />
                        <Icon
                            fontSize='small'
                            sx={{ fontSize: "1rem !important" }}>
                            {/* revisit and debug why MU Icon classes css is missing */}
                            lock
                        </Icon>
                        {/* {children && <Icon>lock</Icon>} */}
                    </SuiBox>
                </ListItem>
            </Tooltip>
        );
    }
);

// Setting default values for the props of SidenavLock
SidenavLock.defaultProps = {
    active: false,
    noCollapse: false,
    children: false,
    open: false,
};

// Typechecking props for the SidenavLock
SidenavLock.propTypes = {
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    noCollapse: PropTypes.bool,
};

export default SidenavLock;
