// import axios from "axios";
// import { api_requests } from "../../utils/rookie/ApiUrlsComponent";

export const setFunnelLabels = label => ({
    type: 'SET_FUNNEL_LABELS',
    payload: label
});

export const setFunnelButtonLabels = label => ({
    type: 'SET_FUNNEL_BUTTON_LABELS',
    payload: label
});


export const setFunnelTextLabels = label => ({
    type: 'SET_FUNNEL_TEXT_LABELS',
    payload: label
});

export const setFunnelTableLabels = label => ({
    type: 'SET_FUNNEL_TABLE_LABELS',
    payload: label
});

export const setError = err => ({
    type: 'SET_ERROR_DETAILS',
    payload: err
});




