import React from "react";
import { Card, CardContent } from "@mui/material";
// import { Card, CardContent } from "../toolkit";
import { Form as ReactStrapForm } from "reactstrap";

const Form = ({ children, ...props }) => {
  return (
    <Card>
      <CardContent
        style={
          props.pdng
            ? { paddingLeft: "2px", paddingRight: "0" }
            : { paddingTop: "24px" }
        }
      >
        <ReactStrapForm {...props}>{children}</ReactStrapForm>
      </CardContent>
    </Card>
  );
};

export default Form;
