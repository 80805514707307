import {
    API_ENDPOINT,
    LINKEDIN_API_ENDPOINT,
    TYMELINE_BACKEND_API_ENDPOINT,
    TYMELINE_TOOLS_ENDPOINT,
} from "utils/rookie/ApiUrlsComponent";

export const COURSE = "COURSES";
export const USER_COURSES = "USER_COURSES";
export const PROFILE = "PROFILE";
export const QUESTION = "QUESTIONS";
export const LEARNING_PATH = "LEARNING_PATHS";
export const LEARNING_PATH_USER = "LEARNING_PATH_USER";
export const USER_LEARNING_PATHS = "USER_LEARNING_PATHS";
export const VACANCY = "VACANCY";
export const APPROVAL = "APPROVAL";
export const DEPARTMENT = "DEPARTMENT";
export const SUPPORT_TICKET = "SUPPORT_TICKET";
export const BUSINESS = "BUSINESS";
export const EVENTADDER = "EVENTADDER";
export const EVENTVALIDATOR = "EVENTVALIDATOR";
export const VERIFICATIONEVENT = "VERIFICATIONEVENT";
export const CONSENTS = "CONSENTS";
export const VALIDATIONEVENT = "VALIDATIONEVENT";
export const VERIFYOTP = "VERIFYOTP";
export const BUSINESS_SIGNUP = "BUSINESS_SIGNUP";
export const VALIDATERECEIVER = "VALIDATERECEIVER";
export const ISSUED_OFFERS = "ISSUED_OFFERS";
export const NOMINATECANDIDATE = "NOMINATECANDIDATE";
export const TYMELINE_SUMMARY = "TYMELINE_SUMMARY";
export const TYMELINE_SUMMARY_REGENERATE = "TYMELINE_SUMMARY_REGENERATE";
export const EMPLOYEE_SUMMARY = "EMPLOYEE_SUMMARY";
export const TYMELINE_SCORE = "TYMELINE_SCORE";
export const TYMELINE_COMPARE = "TYMELINE_COMPARE";
export const TYMELINE_TEAM_COMPATABILITY = "TYMELINE_TEAM_COMPATABILITY";
export const TYMELINE_ANOMALIES = "TYMELINE_ANOMALIES";
export const TYMELINE_SUGGESTED_TESTS = "TYMELINE_SUGGESTED_TESTS";
export const TYMELINE_QUESTIONS = "TYMELINE_QUESTIONS";
export const TYMELINE_BILLING_ENDPOINT = "TYMELINE_BILLING_ENDPOINT";
export const CHECK_IS_CANDIDATE_CRIMINAL = "CHECK_IS_CANDIDATE_CRIMINAL";
export const GRAPH_RATE_CHART = "GRAPH_RATE_CHART";
export const UNLOCK_CANDIDATE_CRIMINAL_CHECK =
    "UNLOCK_CANDIDATE_CRIMINAL_CHECK";
export const CHECK_CANDIDATE_HEALTH_RECORD = "CHECK_CANDIDATE_HEALTH_RECORD";
export const POST_INTEGRATION_AUTH_KEY = "POST_INTEGRATION_AUTH_KEY";
export const UNLOCK_CANDIDATE_HEALTH_CHECK = "UNLOCK_CANDIDATE_HEALTH_CHECK";
export const CHECK_PHYSICAL_ADDRESS_RECORD = "CHECK_PHYSICAL_ADDRESS_RECORD";
export const UNLOCK_PHYSICAL_ADDRESS_CHECK = "UNLOCK_PHYSICAL_ADDRESS_CHECK";
export const TYMELINE_VEC = "TYMELINE_VEC";
export const PEOPLE_SEARCH_RESULTS = "PEOPLE_SEARCH_RESULTS";
export const PROJECT_SEARCH_RESULTS = "PROJECT_SEARCH_RESULTS";
export const CHECK_CANDIDATE_GOVT_ID = "CHECK_CANDIDATE_GOVT_ID";
export const UNLOCK_CANDIDATE_GOVT_ID = "UNLOCK_CANDIDATE_GOVT_ID";
export const POST_SELECTED_KILLS = "POST_SELECTED_KILLS";
export const SELECTED_SKILLS_CHART = "SELECTED_SKILLS_CHART";
export const POST_REQUEST_ENDORSED_SKILLS = "POST_REQUEST_ENDORSED_SKILLS";
export const GET_ENDORSED_SKILLS_REQUESTS = "GET_ENDORSED_SKILLS_REQUESTS";
export const UPDATE_ENDORSED_SKILLS_REQUESTS =
    "UPDATE_ENDORSED_SKILLS_REQUESTS";
export const GROWTH_RECOMMENDATION = "GROWTH_RECOMMENDATION";
export const CHECK_COMPANY = "CHECK_COMPANY";
export const NOMINATE_CANDIDATE_GO_BACKEND = "NOMINATE_CANDIDATE_GO_BACKEND";
export const GET_ALL_NOMINATIONS = "GET_ALL_NOMINATIONS";
export const CHECK_BUSINERSS_EMAIL = "CHECK_BUSINERSS_EMAIL";
export const USER_RECOMMENDATIONS = "USER_RECOMMENDATIONS";
export const START_USER_SURVEY = "START_USER_SURVEY";
export const UPDATE_USER_SURVEY = "UPDATE_USER_SURVEY";
export const GET_TEAM_RECOMMENDATIONS = "GET_TEAM_RECOMMENDATIONS";
export const GET_TEAM_HEALTH = "GET_TEAM_HEALTH";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const USER_STORY = "USER_STORY";
export const PROJECTS = "PROJECTS";

const domainEndpoinConfigs = [
    {
        name: COURSE,
        get: `${API_ENDPOINT}lp/course/{id}`,
        getList: `${API_ENDPOINT}lp/courselist`,
        put: `${API_ENDPOINT}lp/course/{id}`,
        post: `${API_ENDPOINT}lp/course`,
        delete: `${API_ENDPOINT}lp/course/{id}`,
    },
    {
        name: USER_COURSES,
        useMock: true,
        getList: `${API_ENDPOINT}/user/{id}/courses`,
    },
    {
        name: PROFILE,
        get: `${API_ENDPOINT}dbu/user/{id}`,
        getList: `${API_ENDPOINT}dbu/user/`,
        put: `${API_ENDPOINT}dbu/user/{id}`,
        post: `${API_ENDPOINT}dbu/user/`,
    },
    {
        name: QUESTION,
        get: `${API_ENDPOINT}lp/question/{id}`,
        getList: `${API_ENDPOINT}lp/questionlist`,
        put: `${API_ENDPOINT}lp/question/{id}`,
        delete: `${API_ENDPOINT}lp/question/{id}`,
        post: `${API_ENDPOINT}lp/question`,
    },
    {
        name: LEARNING_PATH,
        get: `${API_ENDPOINT}lp/learningPath/{id}`,
        getList: `${API_ENDPOINT}lp/learningPathlist`,
        put: `${API_ENDPOINT}lp/learningPath/{id}`,
        delete: `${API_ENDPOINT}lp/learningPath/{id}`,
        post: `${API_ENDPOINT}lp/learningPath`,
    },
    {
        name: USER_LEARNING_PATHS,
        useMock: true,
        getList: `${API_ENDPOINT}user/{id}/learningPaths`,
    },
    {
        name: LEARNING_PATH_USER,
        get: `${API_ENDPOINT}lp/learningPath/{id}`,
        getList: `${API_ENDPOINT}lp/learningPathlist`,
    },
    {
        name: VACANCY,
        useMock: false,
        get: `${API_ENDPOINT}jp/vacancy/{id}`,
        getList: `${API_ENDPOINT}jp/vacancylist`,
        put: `${API_ENDPOINT}jp/vacancy/{id}`,
        delete: `${API_ENDPOINT}jp/vacancy/{id}`,
        post: `${API_ENDPOINT}jp/vacancy`,
    },
    {
        name: DEPARTMENT,
        post: `${API_ENDPOINT}dpt/department`,
        getList: `${API_ENDPOINT}dpt/departmentlist`,
        get: `${API_ENDPOINT}dpt/department/{id}`,
        put: `${API_ENDPOINT}dpt/department/{id}`,
        delete: `${API_ENDPOINT}dpt/department/{id}`,
    },
    {
        name: PROJECT_SEARCH_RESULTS,
        post: `${API_ENDPOINT}prj/project`,
        getList: `${API_ENDPOINT}prj/projectList`,
        get: `${API_ENDPOINT}prj/project/{id}`,
        put: `${API_ENDPOINT}prj/project/{id}`,
        delete: `${API_ENDPOINT}prj/project/{id}`,
    },
    {
        name: PROJECTS,
        post: `${TYMELINE_TOOLS_ENDPOINT}projects`,
        getList: `${TYMELINE_TOOLS_ENDPOINT}projects`,
        get: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}`,
        put: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}`,
        delete: `${TYMELINE_TOOLS_ENDPOINT}projects/{id}`,
    },
    { name: APPROVAL, put: `${API_ENDPOINT}jp/vacancyapproval/{id}` },
    { name: BUSINESS, post: `${API_ENDPOINT}tl/account/business/entry` },
    {
        name: POST_INTEGRATION_AUTH_KEY,
        post: `${API_ENDPOINT}integration/finch`,
        delete: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/merge`,
    },
    {
        name: SUPPORT_TICKET,
        useMock: true,
    },

    //TYMELINE RELATED API URL
    {
        name: EVENTADDER,
        post: `${API_ENDPOINT}tl/add_adder`,
        put: `${API_ENDPOINT}tl/user/{id}/remove_adder`,
        getList: `${API_ENDPOINT}tl/users/adders_or_validators/list`,
    },
    {
        name: EVENTVALIDATOR,
        post: `${API_ENDPOINT}tl/add_validator`,
        put: `${API_ENDPOINT}tl/user/{id}/remove_validator`,
        getList: `${API_ENDPOINT}tl/users/adders_or_validators/list`,
    },
    {
        name: VERIFICATIONEVENT,
        getList: `${API_ENDPOINT}tl/company/{id}/verification-requests`,
        get: `${API_ENDPOINT}tl/verification/event/{id}`,
        put: `${API_ENDPOINT}tl/verification/event/{id}/update`,
    },
    {
        name: CONSENTS,
        post: `${API_ENDPOINT}tl/consent/verify-otp`,
        get: `${API_ENDPOINT}tl/consent/{id}`,
        getList: `${API_ENDPOINT}tl/consent/all-consents/company/{id}`,
    },
    {
        name: TYMELINE_SUMMARY_REGENERATE,
        post: `${TYMELINE_TOOLS_ENDPOINT}business/new/signup`,
    },
    {
        name: VALIDATIONEVENT,
        post: `${API_ENDPOINT}tl/event/add-event`,
        getList: `${API_ENDPOINT}tl/validation/events/list`,
        get: `${API_ENDPOINT}tl/validation/event/{id}`,
        put: `${API_ENDPOINT}tl/validation/event/{id}/update`,
    },
    {
        name: VERIFYOTP,
        post: `${API_ENDPOINT}tl/current-user/validateotp`,
    },
    {
        name: VALIDATERECEIVER,
        post: `${API_ENDPOINT}tl/tymeline-user/validate`,
    },
    {
        name: BUSINESS_SIGNUP,
        post: `${API_ENDPOINT}business/new/signup`,
    },
    { name: ISSUED_OFFERS, useMock: true },

    { name: NOMINATECANDIDATE, post: `${API_ENDPOINT}tl/nominatecandidate` },
    { name: NOMINATECANDIDATE, post: `${API_ENDPOINT}tl/nominatecandidate` },
    {
        name: TYMELINE_BILLING_ENDPOINT,
        post: `${API_ENDPOINT}tl/billing/email`,
    },

    {
        name: TYMELINE_SUMMARY,
        get: `${TYMELINE_TOOLS_ENDPOINT}users/{account_id}/profile/summary`,
        // post: `https://tymeline-linkedin.azurewebsites.net/summarize`,
    },
    {
        name: TYMELINE_SUMMARY_REGENERATE,
        post: `${TYMELINE_TOOLS_ENDPOINT}tymeline/person/vectors/re-generate`,
    },
    {
        name: EMPLOYEE_SUMMARY,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}summarizeemployee`,
        get: `${TYMELINE_TOOLS_ENDPOINT}team-members/{team_member_id}/profile/summary`,
        // post: `https://tymeline-linkedin.azurewebsites.net/summarize`,
    },
    {
        name: TYMELINE_COMPARE,
        // post: `https://tymeline-linkedin.azurewebsites.net/compare`,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}compare`,
    },
    {
        name: TYMELINE_TEAM_COMPATABILITY,
        // post: `https://tymeline-linkedin.azurewebsites.net/compare`,
        post: `${TYMELINE_TOOLS_ENDPOINT}teams/{team_id}/compatibility-check/team-members/{team_member_id}`,
    },
    {
        name: TYMELINE_ANOMALIES,
        get: `${TYMELINE_TOOLS_ENDPOINT}team-members/{team_member_id}/anomalies`,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}anomalies`,
        // post: `https://tymeline-linkedin.azurewebsites.net/anomalies`,
    },
    {
        name: TYMELINE_SUGGESTED_TESTS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}suggestedtests`,
    },
    {
        name: TYMELINE_QUESTIONS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}questions`,
    },
    {
        name: GRAPH_RATE_CHART,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}raterchart`,
        get: `${TYMELINE_TOOLS_ENDPOINT}users/{account_id}/growth/ratings`,
    },
    {
        name: CHECK_IS_CANDIDATE_CRIMINAL,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}criminalcheck`,
    },
    {
        name: TYMELINE_SCORE,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}tymelinescore`,
    },
    {
        name: UNLOCK_CANDIDATE_CRIMINAL_CHECK,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}unlockcriminalcheck`,
    },
    {
        name: CHECK_CANDIDATE_HEALTH_RECORD,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}healthcheck`,
    },

    {
        name: UNLOCK_CANDIDATE_HEALTH_CHECK,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}unlockhealthcheck`,
    },
    {
        name: CHECK_PHYSICAL_ADDRESS_RECORD,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}addresscheck`,
    },
    {
        name: UNLOCK_PHYSICAL_ADDRESS_CHECK,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}unlockaddresscheck`,
    },
    {
        name: TYMELINE_VEC,
        post: `${TYMELINE_TOOLS_ENDPOINT}tymeline/person/vectors`,
    },
    {
        name: CHECK_CANDIDATE_GOVT_ID,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}govtidcheck`,
    },
    {
        name: UNLOCK_CANDIDATE_GOVT_ID,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}unlockgovtidcheck`,
    },
    {
        name: POST_SELECTED_KILLS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}addskillendorsments`,
    },
    {
        name: SELECTED_SKILLS_CHART,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}skillchart`,
    },
    {
        name: POST_REQUEST_ENDORSED_SKILLS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}createrequestskillendorsement`,
    },
    {
        name: GET_ENDORSED_SKILLS_REQUESTS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}getallskillendorsementrequests`,
    },
    {
        name: UPDATE_ENDORSED_SKILLS_REQUESTS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}updateskillendorsementrequest`,
    },
    {
        name: GROWTH_RECOMMENDATION,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}growthrecommondation`,
        get: `${TYMELINE_TOOLS_ENDPOINT}users/{account_id}/growth/recommendations`,
    },
    {
        name: CHECK_COMPANY,
        post: `${TYMELINE_TOOLS_ENDPOINT}domain/verify`,
    },
    {
        name: PEOPLE_SEARCH_RESULTS,
        post: `${API_ENDPOINT}dbu/people_search`,
    },
    {
        name: NOMINATE_CANDIDATE_GO_BACKEND,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}nominatecandidate`,
    },
    {
        name: GET_ALL_NOMINATIONS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}getallnominations`,
    },
    {
        name: USER_RECOMMENDATIONS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}userrecommendations`,
    },
    {
        name: START_USER_SURVEY,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}getallusersurveys`,
    },
    {
        name: UPDATE_USER_SURVEY,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}updateusersurveys`,
    },
    {
        name: GET_TEAM_RECOMMENDATIONS,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}teamrecommendations`,
    },
    {
        name: GET_TEAM_HEALTH,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}teamhealth`,
    },
    {
        name: CREATE_TEMPLATE,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}createprojecttemplate`,
    },
    {
        name: GET_TEMPLATE,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}getprojecttemplate`,
    },
    {
        name: UPDATE_TEMPLATE,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}updateprojecttemplate`,
    },
    {
        name: DELETE_TEMPLATE,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}deleteprojecttemplate`,
    },
    {
        name: GET_ALL_TEMPLATES,
        post: `${TYMELINE_BACKEND_API_ENDPOINT}getallprojecttemplate`,
    },
    {
        name: CHECK_BUSINERSS_EMAIL,
        post: `${LINKEDIN_API_ENDPOINT}checkemail`,
    },
    {
        name: USER_STORY,
        post: `${TYMELINE_TOOLS_ENDPOINT}stories`,
        get: `${TYMELINE_TOOLS_ENDPOINT}stories/{id}`,
        put: `${TYMELINE_TOOLS_ENDPOINT}stories/{id}`,
        delete: `${TYMELINE_TOOLS_ENDPOINT}stories/{id}`,
        getList: `${TYMELINE_TOOLS_ENDPOINT}stories`,
    },
];

export const getEndpointConfig = (name) =>
    domainEndpoinConfigs.find((config) => config.name === name);
