export const workflowListSelector = (state) => state.workflow.workflowList;
export const funnelWorkflowListSelector = (state) =>
  state.funnelWorkflow.workflowList;
export const userListSelector = (state) => state.dbUser.users;
export const filteredListSelector = (state) =>
  state.dbUser.filteredUsersForSelect;
export const tymelineIdFilteredListSelector = (state) =>
  state.dbUser.filteredTymelineIdUsersForSelect;
export const roleListSelector = (state) => state.role.rolesList;
export const companySelector = (state) => state.company.company_details;
export const filteredRoleListSelector = (state) => state.role.filterdRoleLists;
export const textLabelsSelector = (state) => state.funnelLabels.textLabels;
export const buttonLabelsSelector = (state) => state.funnelLabels.buttonLabels;
export const labelsSelector = (state) => state.funnelLabels.labels;
export const rookieLabelsSelector = (state) => state.labels.labels;
export const rolesSelector = (state) => state.role.role;
// export const trainingMenuSelector = (state) => state.training.trainingMenu;
// export const issueLetterMenuSelector = (state) => state.issue.issueLettersMenu;
export const sideNavSelector = (state) => state.workflow.sideNav;
export const routeSelector = (state) => state?.route?.navigateTo;
export const errorSelector = (state) => state?.error?.status;
export const successSelector = (state) => state?.success?.status;
// export const compensationMenuSelector = (state) =>
//   state.compensation.compensations;
