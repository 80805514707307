import axios from 'axios';
import { companyId } from 'utils/constants';
import { api_requests } from '../../../utils/rookie/ApiUrlsComponent';
import { setCompanyDetails } from '../../company-details/CompanyDetailActions';

export const setWorkflowList = (w) => ({
  type: 'SET_WORKFLOW_LIST',
  payload: w,
});

export const setActionNodeModal = (state) => ({
  type: 'SET_ACTION_NODE_MODAL',
  payload: state,
});

export const setActionNodeDetails = (action) => ({
  type: 'SET_ACTION_NODE_DETAILS',
  payload: action,
});

export const setGeneralWorkflowList = (workflowList) => ({
  type: 'SET_GENERAL_WORKFLOW_LIST',
  payload: workflowList,
});

export const setWorkflowDetails = (workflow) => ({
  type: 'SET_WORKFLOW_DETALS',
  payload: workflow,
});

export const setSearchDetails = (data) => ({
  type: 'SET_SEARCH_DETALS',
  payload: data,
});

export const setSideNavStatus = (data) => ({
  type: 'SET_SIDE_NAV_STATUS',
  payload: data,
});

export const setSearchState = (bool) => ({
  type: 'SET_SEARCH_STATE',
  payload: bool,
});

export const setError = (err) => ({
  type: 'SET_ERROR_DETAILS',
  payload: err,
});

export const getWorkflowList = (role, type) => (dispatch, getState) => {
  const { user } = getState();
  let config;
  if (type === 'single-onboard') {
    config = {
      headers: {
        Authorization: user.currentUser.token,
      },
      params: {
        q: { roles: role },
      },
    };
  } else {
    config = {
      headers: {
        Authorization: user.currentUser.token,
      },
      params: {
        q: { workflow_type: 'Onboarding' },
      },
    };
  }
  axios
    .get(api_requests.workflowList, config)
    .then((response) => {
      let details = response.data;
      dispatch(setWorkflowList(details));
      // dispatch(setOfferDetails(details));
    })
    .catch((err) => setError(err));
};

export const getGeneralWorkflowList = () => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
    params: {
      q: { [companyId]: user.currentUser[companyId] },
    },
  };
  axios
    .get(api_requests.generalWorkflowList, config)
    .then((response) => {
      let details = response.data;
      dispatch(setGeneralWorkflowList(details));
      // dispatch(setOfferDetails(details));
    })
    .catch((err) => setError(err));
};

export const getWorkflow = (id) => (dispatch, getState) => {
  // getCompanyDetails();
  // alert("hi")
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };

  axios
    .get(api_requests.workflow + id + '/', config)
    .then((response) => {
      let details = response.data;

      const completed = new Promise((resolve, reject) => {
        let role = [];
        // eslint-disable-next-line
        details.roles.map((item) => {
          role.push(axios.get(api_requests.role + item, config));
        });
        Promise.all(role).then((responses) => {
          /* eslint-disable array-callback-return */
          const final_data = responses.map((response) => {
            let roles_data = response.data;

            for (const element of details.roles) {
              if (roles_data?.role_id === element) {
                return { label: roles_data.role_title, value: element };
              }
            }
          });
          details.roles = final_data;
          dispatch(setWorkflowDetails(details));
          return;
        });
      });

      completed.then((data) => {
        if (data) {
          // dispatch(setWorkflowDetails(details));
        }
      });

      // dispatch(setWorkflowDetails(details));
    })
    .catch((err) => setError(err));
};

export const getWorkflowDetails = (id) => (dispatch, getState) => {
  // alert("hi")
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };

  axios.get(api_requests.company, config).then((response) => {
    dispatch(setCompanyDetails(response.data));
  });

  axios
    .get(api_requests.workflow + id + '/', config)
    .then((response) => {
      let details = response.data;
      dispatch(setWorkflowDetails(details));
      // dispatch(setWorkflowDetails(details));
    })
    .catch((err) => setError(err));
};

export const createWorkflow = (data, id) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };
  axios
    .put(api_requests.workflow + id + '/', data, config)
    .then((response) => {
      // let details = response.data;
      // dispatch(setOfferDetails(details));
    })
    .catch((err) => setError(err));
};

export const searchFunctionality = (value) => (dispatch, getState) => {
  // console.log(value);
  let finalObject = {
    orientation: {
      name: 'Orientation',
      data: [],
    },
    training: {
      name: 'Trainings',
      data: [],
    },
    compensation: {
      name: 'Compensation & Benefits',
      data: [],
    },
    issueLetters: {
      name: 'Issue Letters',
      data: [],
    },
    company: {
      name: 'Company Policy',
      data: [],
    },
  };
  const { user } = getState();
  let config;

  if (value === '') {
    config = {
      headers: {
        Authorization: user.currentUser.token,
      },
    };
    dispatch(setSearchDetails(null));
    dispatch(setSearchState(false));
  } else {
    dispatch(setSearchState(true));
    config = {
      headers: {
        Authorization: user.currentUser.token,
      },
      params: {
        q: {
          keyword: value,
        },
      },
    };

    axios
      .get(api_requests.search, config)
      .then((response) => {
        let details = response.data;
        // console.log(details);
        // const promises = [];

        const completed = new Promise((resolve, reject) => {
          // alert("hi")
          details.submenus.forEach((element) => {
            // promises.push(axios.get(api_requests.subMenu + element.sub_menu_id, config))
            if (element.menu_type === 'Orientation') {
              element.path = '/orientation';
              finalObject.orientation.data.push(element);
              resolve();
              // dispatch(setSearchDetails(finalElements));
            } else if (element.menu_type === 'Trainings') {
              // alert("hi")
              element.path = '/create-training/';
              finalObject.training.data.push(element);
              resolve();
            } else if (element.menu_type === 'Compensation & Benefits') {
              element.path = '/create-compensation/';
              finalObject.compensation.data.push(element);
              resolve();
            } else if (element.menu_type === 'Company Policies') {
              element.path = '/create-company-policy/';
              finalObject.company.data.push(element);
              resolve();
            } else if (element.menu_type === 'Issue Letters') {
              element.path = '/create-issue-letter/';
              finalObject.issueLetters.data.push(element);
              resolve();
            }
          });
        });
        completed.then(() => {
          dispatch(setSearchDetails(finalObject));
        });
      })
      .catch((err) => setError(err));
  }
};

export const getPaginatedWorkflowDetails =
  (url, type, offset) => (dispatch, getState) => {
    const { user } = getState();

    if (type === 'paginated') {
      const newConfig = {
        headers: {
          Authorization: user.currentUser.token,
        },
        params: {
          limit: 10,
          offset: offset,
        },
      };
      axios
        .get(api_requests.workflowList, newConfig)
        .then((response) => {
          let usersData = response.data;

          // console.log(usersData.dbusers);
          dispatch(setWorkflowList(usersData));

          // dispatch(setUsers(response.data.dbusers.map(element => ({ label: element.userprofile_metadata.email, value: element.profile_id }))));
        })
        .catch((err) => setError(err));
    } else {
      const config = {
        headers: {
          Authorization: user.currentUser.token,
        },
      };
      axios
        .get(url, config)
        .then((response) => {
          let usersData = response.data;

          // console.log(usersData.dbusers);
          dispatch(setWorkflowList(usersData));

          // dispatch(setUsers(response.data.dbusers.map(element => ({ label: element.userprofile_metadata.email, value: element.profile_id }))));
        })
        .catch((err) => setError(err));
    }
  };
