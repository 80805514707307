import { VALIDATIONEVENT } from "services/crudApiConfig";
import getId from "utils/getDomainId";

export const INITIAL_STATE = {
  domainEntities: {},
};

const DomainReducer = (state = INITIAL_STATE, action) => {
  const domain = action.domain?.toLowerCase();
  const id = action?.response?.id ?? "id";
  if (!domain) return state;

  const nextState = {
    ...state,
    [domain]: state[domain] ?? {},
  };

  switch (action.type) {
    case "GET_LIST_DOMAIN_REQUEST": {
      nextState[domain] = {
        loading: true,
      };
      return nextState;
    }

    // Deleteing Loading state if error occurs
    case "GET_LIST_DOMAIN_REJECTED": {
      delete nextState[domain]?.loading;
      return nextState;
    }

    case "GET_DOMAIN_FULFILLED":
    case "POST_DOMAIN_FULFILLED":
    case "UPDATE_DOMAIN_FULFILLED": {
      if (action.domain === VALIDATIONEVENT) {
        nextState[domain] = {
          ...nextState[domain],
          [action.response.eventId]: action.response,
        };
      } else {
        nextState[domain] = {
          ...nextState[domain],
          [id]: action.response,
        };
      }

      return nextState;
    }

    case "DELETE_DOMAIN_FULFILLED":
      delete nextState[domain]["data"][action.id];
      return nextState;

    case "CLEAR_DOMAIN_ENTITY": {
      nextState[domain] = {
        ...nextState[domain],
        id: null,
      };
      return nextState;
    }

    case "GET_LIST_DOMAIN_FULFILLED": {
      const { next_link, prev_link, count } = action.response;
      const domainMap = {};
      action.response.data.forEach((domainData) => {
        domainMap[getId(domainData, action.domain)] = domainData;
      });
      delete nextState[domain]?.loading;
      nextState[domain] = {
        ...nextState[domain],
        data: domainMap,
        next_link,
        prev_link,
        count,
      };
      return nextState;
    }

    default:
      return nextState;
  }
};

export default DomainReducer;
