import { Box, Typography, CustomInput } from "components/toolkit";
import { useState } from "react";
import { Spinner } from "../toolkit";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useFileUpload from "./useFileUpload";

// TODO: Provide a remove option

export const MultiFileUploadField = (props) => {
    const { value, name, disabled, config, placeholder, onChange } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [uploadFilesCount, setUploadFilesCount] = useState(0);
    const [showFileRemove, setShowFileRemove] = useState(false);
    const { uploadFile, removeFile } = useFileUpload();
    const files = value ?? [];

    const FileItem = ({ file, index, showFileRemove }) => {
        const [isRemovingInProgress, setIsRemovingInProgress] = useState(false);
        const handleFileRemove = () => {
            setIsRemovingInProgress(true);
            onFileRemove(file.id);
        };

        return (
            <Box component='span'>
                <Typography
                    variant='body2'
                    component='span'
                    sx={{ fontSize: 14 }}
                    textColor='secondary'>
                    {`${index > 0 ? ", " : ""}${file.name}`}
                    {showFileRemove && !isRemovingInProgress && (
                        <CloseRoundedIcon
                            color='dark'
                            size='medium'
                            sx={{ marginLeft: 0.5, cursor: "pointer" }}
                            onClick={handleFileRemove}
                        />
                    )}
                    {isRemovingInProgress && (
                        <>
                            &nbsp;&nbsp;
                            <Spinner size={12} color='dark' />
                        </>
                    )}
                </Typography>
            </Box>
        );
    };

    const FilesUploaded = ({ showFileRemove }) => {
        const fileItems = [];
        if (files.length > 0) {
            let index = 0;
            while (index < files.length) {
                const file = files[index];
                fileItems.push(
                    <FileItem
                        key={file.id}
                        {...{ file, showFileRemove, index }}
                    />
                );
                index++;
            }
        }
        return <Box>{fileItems}</Box>;
    };

    const onFileSelect = async (event) => {
        const newFiles = event.target.files;
        let allFiles = [...files];
        let index = 0;
        setIsUploading(true);
        setShowFileRemove(false);
        while (index < newFiles.length) {
            setUploadFilesCount(newFiles.length - index);
            const uploadedFile = await uploadFile(newFiles[index]);
            allFiles.push(uploadedFile);
            index++;
            onChange(allFiles);
        }
        setIsUploading(false);
        // todo upload files - get the uploaded file id
    };

    const onFileRemove = async (id) => {
        const removedFile = await removeFile(id);
        const allFiles = [];
        files.forEach((file) => {
            if (file.id !== removedFile.id) {
                allFiles.push(file);
            }
        });
        onChange(allFiles);
    };

    const label = isUploading ? (
        <Box display='flex' gap={0.5} alignItems='center'>
            <Spinner className='mr-1' size={14} color='secondary' />
            <Typography
                textColor='secondary'
                variant='body2'
                sx={{ fontSize: 14 }}>
                {uploadFilesCount == 1
                    ? "Uploading 1 file..."
                    : `Uploading ${uploadFilesCount} files...`}
            </Typography>
        </Box>
    ) : (
        placeholder ?? "Select files"
    );

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                gap={2}>
                <Typography
                    ml={0.5}
                    component='label'
                    variant='caption'
                    fontWeight='bold'>
                    {props.label}
                </Typography>
                {files.length > 0 && (
                    <Box display='flex' alignItems='center' gap={0}>
                        <Typography
                            component='span'
                            variant='caption'
                            fontWeight='medium'
                            textColor='secondary'>
                            {`${files.length} uploaded`}
                        </Typography>
                        {!showFileRemove && (
                            <DeleteOutlineOutlinedIcon
                                color='dark'
                                sx={{ height: "1rem", cursor: "pointer" }}
                                onClick={() => {
                                    setShowFileRemove(true);
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <CustomInput
                id={name}
                name={name}
                type='file'
                label={label}
                accept={config.fileTypes ?? "image/*,.pdf,.doc"}
                onChange={onFileSelect}
                disabled={disabled}
                style={{ opacity: 0 }}
                multiple
            />
            {files.length > 0 && (
                <FilesUploaded showFileRemove={showFileRemove} />
            )}
        </Box>
    );
};
export default MultiFileUploadField;
