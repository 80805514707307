export const MEMBER = "team_member";
export const ADMIN = "admin";

export const NOTIFICATION_SYSTEM_STYLE = {
    NotificationItem: {
        DefaultStyle: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            borderRadius: "4px",
            fontSize: "14px",
        },

        success: {
            borderTop: 0,
            backgroundColor: "#45b649",
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },

        error: {
            borderTop: 0,
            backgroundColor: "#f85032",
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },

        warning: {
            borderTop: 0,
            backgroundColor: "#ffd700",
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },

        info: {
            borderTop: 0,
            background: "linear-gradient(to right, #6a82fb, #fc5c7d)",
            WebkitBoxShadow: 0,
            MozBoxShadow: 0,
            boxShadow: 0,
        },
    },

    Title: {
        DefaultStyle: {
            margin: 0,
            padding: 0,
            paddingRight: 5,
            color: "#fff",
            display: "inline-flex",
            fontSize: 20,
            fontWeight: "bold",
            // left: '15px',
            // position: 'absolute',
            // top: '50%',
        },
    },

    MessageWrapper: {
        DefaultStyle: {
            display: "block",
            color: "#fff",
            width: "100%",
        },
    },

    Dismiss: {
        DefaultStyle: {
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "inherit",
            fontSize: 20,
            color: "#f2f2f2",
            position: "relative",
            margin: 0,
            padding: 0,
            background: "none",
            borderRadius: 0,
            opacity: 1,
            width: 20,
            height: 20,
            textAlign: "initial",
            float: "none",
            top: "unset",
            right: "unset",
            lineHeight: "inherit",
        },
    },

    Action: {
        DefaultStyle: {
            background: "#fff",
            borderRadius: "2px",
            padding: "6px 20px",
            fontWeight: "bold",
            margin: "10px 0 0 0",
            border: 0,
        },

        success: {
            backgroundColor: "#45b649",
            color: "#fff",
        },

        error: {
            backgroundColor: "#f85032",
            color: "#fff",
        },

        warning: {
            backgroundColor: "#ffd700",
            color: "#fff",
        },

        info: {
            backgroundColor: "#00c9ff",
            color: "#fff",
        },
    },

    ActionWrapper: {
        DefaultStyle: {
            margin: 0,
            padding: 0,
        },
    },
};

export const apiOptions = {
    website: "linktoWebsite",
    video: "videoLink",
    pdfUpload: "pdfUpload",
    embed: "embedLink",
};

export const Options = {
    website: "website",
    video: "video",
    pdfUpload: "pdfUpload",
    embed: "embed",
};

export const categoriesList = [
    { label: "Sales", value: "Sales" },
    { label: "Admin", value: "Admin" },
    { label: "Technology", value: "Technology" },
    { label: "Developer", value: "Developer" },
    { label: "Marketing", value: "Marketing" },
    { label: "Sports", value: "Sports" },
    { label: "Parties", value: "Parties" },
    { label: "Movies", value: "Movies" },
    { label: "Cultural", value: "Cultural" },
    { label: "Sports", value: "Sports" },
    { label: "Events", value: "Events" },
    { label: "Hiking", value: "Hiking" },
];

export const companyId = "businessId";

export const IN_PROGRESS = "1";
export const COMPLETED = "2";
export const SELECTED = "3";
export const ON_BOARDING = "4";

export const INPROGRESS = "InProgress";
// export const COMPLETED = "2";
export const ASSIGNED = "Assigned";

export const COURSE = "course";
export const LEARNING_PATH = "learningPath";

// export const overviewType = ["All", "Courses", "Learning Paths"];
export const overviewType = [
    { value: "All", key: "all" },
    { value: "Courses", key: "course" },
    { value: "Learning Paths", key: "learningPath" },
];

// Event Adding Types

export const EVENT_TYPES = {
    projectCompletion: "Project Completion",
    // issueJobOffer: "Issue a job offer",
    tenure: "Tenure",
    freelancer: "Freelancer",
    // joining: "Joining",
    resignation: "Resignation",
    salaryAppraisal: "Salary appraisal",
    trainingCompletion: "Training completion",
    accomplishment: "Accomplishment",
    offerAccepted: "Offer accepted",
    offerRejected: "Offer rejected",
    appreciation: "Appreciation",
    // offerDishonored: "Offer dishonored",
    other: "Other",
};

export const STATUS_CODES = {
    success: 200,
};
export const userPermissions = {
    peopleHub: "peopleHub",
    verificationHub: "verificationHub",
    admin: "admin",
};
export const TYMELINE_ACCESS_ERROR_MESSAGE =
    "Passcode has expired. Generate new one";

export const INVALID_PASSCODE_MESSAGE =
    "Invalid Passcode. Please enter the right OTP ";

export const CRIMINAL_RECORD_MESSAGES = {
    noRecord: "No Record",
    companyPresent: "Company Present",
    yesOrNo: "Yes",
    unlock: "Unlock",
    inProgress: "InProgress",
    unlocked: "Unlocked",
};
export const EMAIL_CHECK_PASS_CODE = "cfe6304d4d10da81b6b960011d9a801f";

export const healthCheckUpTests = `

1. Sugar Profile

HbA1C

Fasting Blood Sugar

2. Kidney Profile

BUN / Sr. CREATININE RATIO

Blood Urea Nitrogen (BUN)

CREATININE - SERUM

Uric Acid

4. Lipid Profile

TOTAL CHOLESTEROL

HDL CHOLESTEROL - DIRECT

LDL CHOLESTEROL - DIRECT

LDL / HDL RATIO

NON-HDL CHOLESTEROL

TC/ HDL CHOLESTEROL RATIO

TRIGLYCERIDES

5. Thyroid Profile

T3

T4

Thyroid Stimulating Hormone (TSH)

`;

export const vacancyType = [
    "All Records",
    "Tenure",
    "Freelancer",
    "Joining",
    "Resignation",
    "Salary Appraisal",
    "Training Completion",
    "Project Completion",
    "Accomplishment",
    "Validated",
    "Unverified",
    "Rejected",
    "On Hold",
];

export const recordStatus = ["Validated", "Unverified", "Rejected", "On Hold"];
export const recordType = [
    "Tenure",
    "Freelancer",
    "Resignation",
    "Salary Appraisal",
    "Project",
    "Accomplishment",
    "Validated",
    "Unverified",
    "Rejected",
    "On Hold",
];

export const PEOPLE_RESULTS = {
    data: [
        {
            id: "241-608-2250-V",
            accountId: "241-608-2250-V",
            firstName: "Vijeth",
            lastName: "Simha",
            email: "vijeth@tymeline.id",
            phone: null,
            address: " ",
            role: "633fff829006b739fae2de58",
            jobTitle: "",
            team: [
                "Aryas",
                "Knights",
                "Aliens",
                "Tamers",
                "TeamX",
                "UFA",
                "Fighters",
            ],
            department: "6410f0b9bd7d833718bbfa06",
            skills: [
                "React",
                "Angular",
                "Next JS",
                "Gatsby",
                "Python",
                "Node JS",
                "Deno",
            ],
            access: [],
            profilePhoto: {},
            profileInformation: null,
            profileBGPhoto: {},
            linkedinUrl: " ",
            facebookUrl: " ",
            twitterUrl: " ",
            githubUrl: " ",
            createdAt: "2023-09-05T08:50:26.660000",
            updatedAt: null,
            businessId: "B-126-264-3729-S",
            is_adder: true,
            is_validator: true,
            is_superuser: true,
            role_title: "Frontend Developer",
        },
        {
            id: "341-608-2250-D",
            accountId: "341-608-2250-D",
            firstName: "Vinay",
            lastName: "Simha",
            email: "vinay@tymeline.id",
            phone: null,
            address: " ",
            role: "633fff829006b739fae2de58",
            jobTitle: "",
            team: [
                "Juping jacks",
                "Paltans",
                "Yorkshires",
                "Raptors",
                "Eagles",
                "Knights",
                "Aliens",
                "Tamers",
            ],
            department: "6410f0b9bd7d833718bbfa06",
            skills: [
                "Python",
                "Node JS",
                "Deno",
                "Ruby",
                "C",
                "C++",
                "AI",
                "SQL",
                "Postgres",
            ],
            access: [],
            profilePhoto: {},
            profileInformation: null,
            profileBGPhoto: {},
            linkedinUrl: " ",
            facebookUrl: " ",
            twitterUrl: " ",
            githubUrl: " ",
            createdAt: "2023-09-05T08:50:26.660000",
            updatedAt: null,
            businessId: "B-126-264-3729-S",
            is_adder: true,
            is_validator: true,
            is_superuser: true,
            role_title: "Backend Developer",
        },
    ],
};

export const PROJECT_RESULTS = {
    data: [
        {
            id: "241-608-2250-V",
            accountId: "241-608-2250-V",
            projectName: "Resq",
            address: " ",
            role: "633fff829006b739fae2de58",
            jobTitle: "",
            team: [
                "Aryas",
                "Knights",
                "Aliens",
                "Tamers",
                "TeamX",
                "UFA",
                "Fighters",
            ],
            department: "6410f0b9bd7d833718bbfa06",
            projectCategory: ["International ", "Large Scale", "Public Sector"],
            access: [],
            createdAt: "2023-09-05T08:50:26.660000",
            updatedAt: null,
            businessId: "B-126-264-3729-S",
            role_title: "Frontend Developer",
        },
        {
            id: "241-608-2122-V",
            accountId: "241-608-2122-V",
            projectName: "Zipha",
            address: " ",
            role: "633fff829006b739fae2de58",
            jobTitle: "",
            team: [
                "Juping jacks",
                "Paltans",
                "Yorkshires",
                "Raptors",
                "Eagles",
                "Knights",
                "Aliens",
                "Tamers",
            ],
            department: "6410f0b9bd7d833718bbfa06",
            projectCategory: [
                "National",
                "Conventional Technology",
                "High-Tech",
            ],
            access: [],
            createdAt: "2023-09-05T08:50:26.660000",
            updatedAt: null,
            businessId: "B-126-264-3729-S",
            role_title: "Backend Developer",
        },
    ],
};

export const LINKEDIN_PASS_CODE =
    "20BEC3C7D362FDDAFAA8FB95A23FA9089EF6067C107C0C3C4605998D79DE5E88";

export const teamIconsList = [
    {
        value: "fas fa-graduation-cap",
        label: (
            <span>
                <span className='mr-2 fas fa-graduation-cap' />
                Graduation cap
            </span>
        ),
    },
    {
        value: "fas fa-space-shuttle",
        label: (
            <span>
                <span className='mr-2 fas fa-space-shuttle' />
                Shuttle
            </span>
        ),
    },
    {
        value: "fas fa-user-astronaut",
        label: (
            <span>
                <span className='mr-2 fas fa-user-astronaut' />
                Astronaut
            </span>
        ),
    },
    {
        value: "fas fa-guitar",
        label: (
            <span>
                <span className='mr-2 fas fa-hockey-puck' />
                Guitar
            </span>
        ),
    },
    {
        value: "fas fa-hockey-puck",
        label: (
            <span>
                <span className='mr-2 fas fa-hockey-puck' />
                Hockey puck
            </span>
        ),
    },
    {
        value: "fas fa-mug-hot",
        label: (
            <span>
                <span className='mr-2 fas fa-mug-hot' />
                Hot mug
            </span>
        ),
    },
    {
        value: "fas fa-coffee",
        label: (
            <span>
                <span className='mr-2 fas fa-coffee' />
                Coffee
            </span>
        ),
    },
    {
        value: "fas fa-chalkboard",
        label: (
            <span>
                <span className='mr-2 fas fa-chalkboard' />
                Chalk Board
            </span>
        ),
    },
    {
        value: "fas fa-solar-panel",
        label: (
            <span>
                <span className='mr-2 fas fa-solar-panel' />
                Solar Panel
            </span>
        ),
    },
    {
        value: "fas fa-parachute-box",
        label: (
            <span>
                <span className='mr-2 fas fa-parachute-box' />
                Parachute box
            </span>
        ),
    },
    {
        value: "fas fa-sitemap",
        label: (
            <span>
                <span className='mr-2 fas fa-sitemap' />
                Sitemap
            </span>
        ),
    },
    {
        value: "fas fa-hat-cowboy",
        label: (
            <span>
                <span className='mr-2 fas fa-hat-cowboy' />
                Cowboy hat
            </span>
        ),
    },
    {
        value: "fas fa-landmark",
        label: (
            <span>
                <span className='mr-2 fas fa-landmark' />
                Landmark
            </span>
        ),
    },
    {
        value: "fas fa-crown",
        label: (
            <span>
                <span className='mr-2 fas fa-crown' />
                Crown
            </span>
        ),
    },
    {
        value: "fab fa-sistrix",
        label: (
            <span>
                <span className='mr-2 fab fa-sistrix' />
                Sistrix
            </span>
        ),
    },
    {
        value: "fab fa-hotjar",
        label: (
            <span>
                <span className='mr-2 fab fa-hotjar' />
                Hotjar
            </span>
        ),
    },
    {
        value: "far fa-newspaper",
        label: (
            <span>
                <span className='mr-2 far fa-newspaper' />
                News paper
            </span>
        ),
    },
    {
        value: "far fa-address-book",
        label: (
            <span>
                <span className='mr-2 far fa-address-book' />
                Address book
            </span>
        ),
    },
    {
        value: "far fa-handshake",
        label: (
            <span>
                <span className='mr-2 far fa-handshake' />
                Handshake
            </span>
        ),
    },
    {
        value: "fas fa-medal",
        label: (
            <span>
                <span className='mr-2 fas fa-medal' />
                Medal
            </span>
        ),
    },
    {
        value: "fas fa-microscope",
        label: (
            <span>
                <span className='mr-2 fas fa-microscope' />
                Microscope
            </span>
        ),
    },
    {
        value: "fab fa-mendeley",
        label: (
            <span>
                <span className='mr-2 fab fa-mendeley' />
                Mendeley
            </span>
        ),
    },
];

export const endorsementErrorText =
    "Sorry you have already requested endorsement with these users. May be try sending them a reminder";

export const consentType = {
    access: "access",
    dashboard: "dashboard",
};

export const teamRecommendationsConstants = {
    Accountability: "Accountability",
    BuildTrust: "Build Trust",
    ClearGoalsAndRoles: "Clear Goals and Roles",
    ConflictResolution: "Conflict Resolution",
    Efficiency: "Efficiency",
    Happiness: "Happiness",
    InnovationAndCreativity: "Innovation and Creativity",
    Retention: "Retention",
    TeamCommunication: "Team Communication",
    TeamMorale: "Team Morale",
    Upskilling: "Upskilling",
    RecognitionAndAppreciation: "Recognition and Appreciation",
};

export const deleteLater = [
    {
        id: "5f125e3c-8165-4518-9e5f-9b735fa52df6",
        remote_id: "50dc00ea-2221-49de-b424-02cf38857f6f",
        created_at: "2024-04-09T05:23:56.12Z",
        modified_at: "2024-04-09T05:24:06.98Z",
        name: "Quanscendence",
        remote_was_deleted: false,
        field_mappings: {
            linked_account_defined_targets: {},
            organization_defined_targets: {},
        },
        integration_id: "ca685dd0-ee4c-46e7-b905-d81c15395114",
        integration_type: "ticketing",
        business_id: "B-126-264-3729-S",
        users: [
            {
                id: "3e3c1e80-bc69-4f52-ae61-181b4bb0c9d5",
                remote_id: "4a4c1116-6132-44c9-808a-d1c457b4d1f2",
                created_at: "2024-04-09T05:23:56.105Z",
                modified_at: "2024-04-09T05:23:56.105Z",
                name: "vijeth@quanscendence.com",
                email_address: "vijeth@quanscendence.com",
                is_active: true,
                teams: ["5f125e3c-8165-4518-9e5f-9b735fa52df6"],
                roles: [],
                remote_was_deleted: false,
                field_mappings: {
                    linked_account_defined_targets: {},
                    organization_defined_targets: {},
                },
                integration_id: "ca685dd0-ee4c-46e7-b905-d81c15395114",
                integration_type: "ticketing",
                business_id: "B-126-264-3729-S",
            },
            {
                id: "3e3d1e80-bc69-4f52-ae61-181b4bb0c9d5",
                remote_id: "4a4d1116-6132-44c9-808a-d1c457b4d1f2",
                created_at: "2024-04-09T05:23:56.105Z",
                modified_at: "2024-04-09T05:23:56.105Z",
                name: "nikhil@quanscendence.com",
                email_address: "nikhil@quanscendence.com",
                is_active: true,
                teams: ["5f125e3c-8165-4518-9e5f-9b735fa52df6"],
                roles: [],
                remote_was_deleted: false,
                field_mappings: {
                    linked_account_defined_targets: {},
                    organization_defined_targets: {},
                },
                integration_id: "ca685dd0-ee4c-46e7-b905-d81c15395114",
                integration_type: "ticketing",
                business_id: "B-126-264-3729-S",
            },
            {
                id: "3e5c1e80-bc69-4f52-ae61-181b4bb0c9d5",
                remote_id: "4b4c1116-6132-44c9-808a-d1c457b4d1f2",
                created_at: "2024-04-09T05:23:56.105Z",
                modified_at: "2024-04-09T05:23:56.105Z",
                name: "shrikar@quanscendence.com",
                email_address: "shrikar@quanscendence.com",
                is_active: true,
                teams: ["5f125e3c-8165-4518-9e5f-9b735fa52df6"],
                roles: [],
                remote_was_deleted: false,
                field_mappings: {
                    linked_account_defined_targets: {},
                    organization_defined_targets: {},
                },
                integration_id: "ca685dd0-ee4c-46e7-b905-d81c15395114",
                integration_type: "ticketing",
                business_id: "B-126-264-3729-S",
            },
        ],
    },
    {
        id: "5e125e3c-8165-4518-9e5f-9b735fa52df6",
        remote_id: "50fg00ea-2221-49de-b424-02cf38857f6f",
        created_at: "2024-05-09T05:23:56.12Z",
        modified_at: "2024-05-09T05:24:06.98Z",
        name: "Tymeline",
        remote_was_deleted: false,
        field_mappings: {
            linked_account_defined_targets: {},
            organization_defined_targets: {},
        },
        integration_id: "ca685dd0-te4c-46e7-b905-d81c15395114",
        integration_type: "ticketing",
        business_id: "B-126-264-3729-F",
        users: [
            {
                id: "3e3c1e80-bc69-7f52-ae61-181b4bb0c9d5",
                remote_id: "4a4c15116-6132-44c9-808a-d1c457b4d1f2",
                created_at: "2024-04-09T05:23:56.105Z",
                modified_at: "2024-04-09T05:23:56.105Z",
                name: "ranjitha@quanscendence.com",
                email_address: "ranjitha@quanscendence.com",
                is_active: true,
                teams: ["5f125e3c-8165-4518-9e5f-9b735fa52df6"],
                roles: [],
                remote_was_deleted: false,
                field_mappings: {
                    linked_account_defined_targets: {},
                    organization_defined_targets: {},
                },
                integration_id: "ca645dd0-ee4c-46e7-b905-d81c15395114",
                integration_type: "ticketing",
                business_id: "B-126-264-3729-F",
            },
            {
                id: "3e3d1e34-bc69-4f52-ae61-181b4bb0c9d5",
                remote_id: "4a6y1116-6132-44c9-808a-d1c457b4d1f2",
                created_at: "2024-04-09T05:23:56.105Z",
                modified_at: "2024-04-09T05:23:56.105Z",
                name: "anirudh@quanscendence.com",
                email_address: "anirudh@quanscendence.com",
                is_active: true,
                teams: ["5f125e3c-8165-4518-9e5f-9b735fa52df6"],
                roles: [],
                remote_was_deleted: false,
                field_mappings: {
                    linked_account_defined_targets: {},
                    organization_defined_targets: {},
                },
                integration_id: "ca685tt0-ee4c-46e7-b905-d81c15395114",
                integration_type: "ticketing",
                business_id: "B-126-264-3729-F",
            },
            {
                id: "3e5c1e45-bc69-4f52-ae61-181b4bb0c9d5",
                remote_id: "4b4c1116-6167-44c9-808a-d1c457b4d1f2",
                created_at: "2024-04-09T05:23:56.105Z",
                modified_at: "2024-04-09T05:23:56.105Z",
                name: "lohith@quanscendence.com",
                email_address: "lohith@quanscendence.com",
                is_active: true,
                teams: ["5f125e3c-8165-4518-9e5f-9b735fa52df6"],
                roles: [],
                remote_was_deleted: false,
                field_mappings: {
                    linked_account_defined_targets: {},
                    organization_defined_targets: {},
                },
                integration_id: "ca685dd0-ee4c-46e7-b905-d81c15395114",
                integration_type: "ticketing",
                business_id: "B-126-264-3729-F",
            },
        ],
    },
];

export const teamGoals = [
    {
        id: "41f39d6c-51f2-43bf-af40-ff550e6cf9f9",
        created_at: "2024-04-07T03:48:46.337Z",
        updated_at: "0001-01-01T00:00:00Z",
        title: "Integrate the Measureone",
        description: "Develop a new feature for the website.",
        start_date: "2024-04-07T15:04:05Z",
        due_date: "2024-05-07T15:04:05Z",
        started_at: "0001-01-01T00:00:00Z",
        ended_at: "0001-01-01T00:00:00Z",
        progress: 0,
        status: "In Progress",
        priority: 50,
        types: [{ type: "Feature Development" }],
        categories: [{ name: "Development" }],
        business_id: "",
        owner_id: "834-170-8409-N",
        creator_id: "834-170-8409-N",
        team_ids: ["6548fc837c4f73c1b7f86198", "65e83f9baa5edefe15009b2f"],
        teams: null,
        tags: ["website", "feature", "collaboration"],
        comments: null,
        addition_data: null,
        instructions: [
            "Work collaboratively to implement both frontend and backend components.",
            "Ensure code quality through code reviews and testing.",
            "Communicate regularly to address any issues or blockers.",
        ],
    },
    {
        id: "8714c581-9fcb-4498-acff-9ad1cac52c80",
        created_at: "2024-04-16T06:49:40.615Z",
        updated_at: "0001-01-01T00:00:00Z",
        title: "New details",
        description: "<p>Test</p>",
        start_date: "2023-06-04T00:00:00Z",
        due_date: "2024-04-14T00:00:00Z",
        started_at: "0001-01-01T00:00:00Z",
        ended_at: "0001-01-01T00:00:00Z",
        progress: 0,
        status: "",
        priority: 34,
        types: [],
        categories: [],
        business_id: "B-126-264-3729-S",
        owner_id: "899-685-7938-G",
        creator_id: "834-170-8409-N",
        team_ids: ["6548fc837c4f73c1b7f86198"],
        teams: null,
        tags: null,
        comments: null,
        addition_data: null,
        instructions: ["<p>Test</p>"],
    },
];

export const teamMemberGoals = [
    {
        id: "39c1b253-e6f5-4359-95d2-c00873af8c1b",
        created_at: "2024-04-18T11:38:42.211Z",
        updated_at: "0001-01-01T00:00:00Z",
        organization_goal_id: "{organization_goal_id}",
        team_goal_id: "1b593ab3-149d-4ea7-93ec-7d53221dea64",
        team_member_id: "6548fc837c4f73c1b7f86198",
        title: "Launch a manager training program",
        description: "<p>Launch a manager training program</p>",
        start_date: "2024-04-22T00:00:00Z",
        due_date: "2024-05-02T00:00:00Z",
        started_at: "0001-01-01T00:00:00Z",
        ended_at: "0001-01-01T00:00:00Z",
        progress: 50,
        status: "",
        priority: 23,
        types: [],
        categories: [],
        owner_id: "834-170-8409-N",
        creator_id: "834-170-8409-N",
        tags: null,
        comments: null,
        addition_data: null,
        instructions: ["<p>Test member goal</p>"],
        milestones: [
            {
                title: "Read 3 books on effective communication",
                deadline: "2024-06-22T00:00:00Z",
                status: "Inprogress",
            },
            {
                title: "Request feedback 5 times for this skill in the next quarter",
                deadline: "2024-05-22T00:00:00Z",
                status: "Completed",
            },
            {
                title: "Request feedback 5 times for this skill in the next quarter",
                deadline: "2024-05-22T00:00:00Z",
                status: "On hold",
            },
        ],
    },
    {
        id: "6d22e6c2-ca7b-44e0-a274-a68c595732bb",
        created_at: "2024-04-18T11:40:03.862Z",
        updated_at: "0001-01-01T00:00:00Z",
        organization_goal_id: "{organization_goal_id}",
        team_goal_id: "1b593ab3-149d-4ea7-93ec-7d53221dea64",
        team_member_id: "834-170-8409-N",
        title: "Create effective onboarding for new employees",
        description: "<p>Create effective onboarding for new employees</p>",
        start_date: "2024-01-16T00:00:00Z",
        due_date: "2024-04-30T00:00:00Z",
        started_at: "0001-01-01T00:00:00Z",
        ended_at: "0001-01-01T00:00:00Z",
        progress: 60,
        status: "",
        priority: 32,
        types: [],
        categories: [],
        owner_id: "834-170-8409-N",
        creator_id: "834-170-8409-N",
        tags: null,
        comments: null,
        addition_data: null,
        instructions: ["<p>Finish the landing page</p>"],
        milestones: [
            {
                title: "Define top learning path need / priority for each of the four teams",
                deadline: "2024-09-22T00:00:00Z",
                status: "Inprogress",
            },
            {
                title: "Increase onboarding survey results NPS by 10%",
                deadline: "2024-10-22T00:00:00Z",
                status: "Completed",
            },
        ],
    },
    {
        id: "6d22e6c2-ca7b-23e0-a274-a68c595732bb",
        created_at: "2024-04-18T11:40:03.862Z",
        updated_at: "0001-01-01T00:00:00Z",
        organization_goal_id: "{organization_goal_id}",
        team_goal_id: "1b593ab3-149d-4ea7-93ec-7d53221dea64",
        team_member_id: "834-170-8409-N",
        title: "Meet our hiring targets this quarter",
        description: "<p>Meet our hiring targets this quarter</p>",
        start_date: "2024-01-16T00:00:00Z",
        due_date: "2024-09-30T00:00:00Z",
        started_at: "0001-01-01T00:00:00Z",
        ended_at: "0001-01-01T00:00:00Z",
        progress: 90,
        status: "",
        priority: 32,
        types: [],
        categories: [],
        owner_id: "834-170-8409-N",
        creator_id: "834-170-8409-N",
        tags: null,
        comments: null,
        addition_data: null,
        instructions: ["<p>Finish the landing page</p>"],
        milestones: [
            {
                title: "Create 4 onboardings (1 for each team).",
                deadline: "2024-05-22T00:00:00Z",
                status: "Inprogress",
            },
            {
                title: "Hire new sales team lead",
                deadline: "2024-06-22T00:00:00Z",
                status: "Inprogress",
            },
        ],
    },
];

function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getFakeValue() {
    await delay(3000);
    return teamGoals;
}

export const selectIconStyle =
    'url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!--%20License%3A%20PD.%20Made%20by%20stephenhutchings%3A%20https%3A%2F%2Fgithub.com%2Fstephenhutchings%2Fmicrons%20--%3E%3Csvg%20fill%3D%22%23495057%22%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%220%20-6%20524%20524%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%3E%3Ctitle%3Edown%3C%2Ftitle%3E%3Cpath%20d%3D%22M64%20191L98%20157%20262%20320%20426%20157%20460%20191%20262%20387%2064%20191Z%22%20%2F%3E%3C%2Fsvg%3E") no-repeat right 0.5rem center/13px 14px';
export const fakeIntegrationsList = [
    {
        businessId: "B-126-264-3729-S",
        integrationSource: "merge",
        integratedPlatform: "Linear",
        typeofIntegration: "ticketing",
        integrationId: "ba58ffa3-4783-450f-b650-9752696e6ed9",
        integratedOn: "2024-04-30T11:06:21.888000",
        image: "https://merge-api-production.s3.amazonaws.com/media/Linear.png",
        squareImage:
            "https://merge-api-production.s3.amazonaws.com/media/Linear_Square_Logo.png",
    },
    {
        businessId: "B-126-264-3729-S",
        integrationSource: "merge",
        integratedPlatform: "Linear",
        typeofIntegration: "ticketing",
        integrationId: "ba58ffa3-4783-452d-b635-9752696e6ed9",
        integratedOn: "2024-04-30T11:06:21.888000",
        image: "https://merge-api-production.s3.amazonaws.com/media/Linear.png",
        squareImage:
            "https://merge-api-production.s3.amazonaws.com/media/Linear_Square_Logo.png",
    },
];
