import axios from "axios";
import { api_requests } from "../../utils/rookie/ApiUrlsComponent";

export const setCurrentUser = (user) => ({
    type: "SET_CURRENT_USER",
    payload: user,
});

export const setError = (err) => ({
    type: "SET_ERROR_DETAILS",
    payload: err,
});

export const setOrientation = (orientation) => ({
    type: "SET_ORIENTATION",
    payload: orientation,
});

export const setUserTokenValues = (tokenvalues) => ({
    type: "SET_USER_TOKEN_VALUES",
    payload: tokenvalues,
});

export const setTraining = (training) => ({
    type: "SET_TRAINING_ID",
    payload: training,
});

export const postCreateOrientation = (data, finish) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };

    const link = window.location.origin;

    axios
        .post(api_requests.item, data, config)
        .then((response) => {
            if (finish === "finish") {
                window.location.href = `${link}/admin/orientation-view`;
                // history.push("/orientation-view");
            } else {
                dispatch(setOrientation(response.data.item_id));
            }
            // localStorage.setItem('oid', response.data.orientation_id)
            // dispatch(setOrientation(response.data.item_id))
        })
        .catch((err) => {
            dispatch(setError(err.response.data.detail));
            setTimeout(() => {
                dispatch(setError(null));
            }, 6000);
        });
};

export const postCreateTraining = (data) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };
    // console.log(config);
    axios
        .post(api_requests.item, data, config)
        .then((response) => {
            // localStorage.setItem('oid', response.data.orientation_id)
            dispatch(setTraining(response.data.item_id));
        })
        .catch((err) => {
            dispatch(setError(err.response.data.detail));
            setTimeout(() => {
                dispatch(setError(null));
            }, 6000);
        });
};
