const INITIAL_STATE = {
  status: null,
  message: "",
};
const APISuccessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `RESPONSE_STATUS`:
      return {
        ...state,
        status: action.status,
        message: action.message ?? "UPDATE API SUCCESS",
      };
    default:
      return state;
  }
};

export default APISuccessReducer;
