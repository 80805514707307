import { COURSE, LEARNING_PATH } from "services/crudApiConfig";

const INITIAL_STATE = {
  navigateTo: null,
};
const RouteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `POST_${COURSE}_FULFILLED`:
    case `POST_${LEARNING_PATH}_FULFILLED`:
      return {
        ...state,
        navigateTo: action.response.navigateTo + action.response.id,
      };
    case `UPDATE_${LEARNING_PATH}_FULFILLED`:
    case `UPDATE_${COURSE}_FULFILLED`:
      return {
        ...state,
        navigateTo: action.response.navigateTo,
      };
    case "APP_NAVIGATE_TO":
      return {
        ...state,
        navigateTo: action.navigateTo,
      };
    default:
      return state;
  }
};

export default RouteReducer;
