// eslint-disable-next-line
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routeSelector } from "selectors/labelSelectors";

const AppNavigation = () => {
  const navigateTo = useSelector(routeSelector);
  const history = useHistory();
  useEffect(() => {
    if (navigateTo) {
      history.push(navigateTo);
    }
  }, [history, navigateTo]);
  return null;
};

export default AppNavigation;
