export const selectCompanyPrimaryColour = ({
  company: { company_details, basic_details },
}) => [
  company_details?.theme_primary_colour ?? basic_details?.theme_primary_colour,
  company_details?.theme_primary_colour_two ??
    basic_details?.theme_primary_colour_two,
];

export const selectCompanySecondaryColour = ({
  company: { company_details, basic_details },
}) => [
  company_details?.theme_secondary_colour ??
    basic_details?.theme_secondary_colour,
  company_details?.theme_secondary_colour_two ??
    basic_details?.theme_secondary_colour_two,
];

export const selectCompanyName = ({
  company: { company_details, basic_details },
}) => company_details?.company_display_name ?? basic_details?.company_name;

export const selectCompanyLogo = ({
  company: { company_details, basic_details },
}) => company_details?.logo?.logo && basic_details?.logo?.logo;

export const selectNewCompanyLogo = ({
  company: { company_details, basic_details },
}) => company_details?.logo?.logo ?? basic_details?.logo?.logo;
