/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";

// Soft UI Dashboard React example components
// import DefaultNavbar from "components/toolkit/Navbars/DefaultNavbar";
import PageLayout from "components/toolkit/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

import { useHistory } from "react-router-dom";

// Custom styles for the Baise
import styles from "layouts/authentication/components/CoverLayout/styles";

// Soft UI Dashboard React page layout routes
// import routes from "routes";

import Logo from "../../../../assets/images/Tymeline-logo.png";
function CoverLayout({
    color,
    header,
    title,
    description,
    image,
    logoImage,
    top,
    children,
    gridWidth = 3,
}) {
    const classes = styles({ image });

    const navigateToTymelineApp = () => {
        window.location.href = "https://www.tymeline.app/";
    };
    return (
        <PageLayout background='white'>
            <Grid
                container
                justifyContent='center'
                className={classes.coverLayout}
                style={{ marginTop: top }}>
                <Grid item xs={11} sm={8} md={5} xl={gridWidth}>
                    <SuiBox>
                        <SuiBox pt={3} px={3}>
                            <SuiBox
                                textAlign='center'
                                marginBottom={2}
                                sx={{ cursor: "pointer" }}
                                onClick={navigateToTymelineApp}>
                                {logoImage ? (
                                    <img
                                        width='100px'
                                        height='100px'
                                        src={logoImage ?? Logo}
                                        alt=''
                                    />
                                ) : (
                                    <img src={Logo} alt='' />
                                )}
                            </SuiBox>
                            {!header ? (
                                <>
                                    {title && (
                                        <SuiBox
                                            mb={1}
                                            display='flex'
                                            justifyContent='center'>
                                            <SuiTypography
                                                variant='h1'
                                                fontWeight='bold'
                                                textColor={color}
                                                textGradient>
                                                {title}
                                            </SuiTypography>
                                        </SuiBox>
                                    )}
                                    {description && (
                                        <SuiBox
                                            mt={6}
                                            display='flex'
                                            justifyContent='center'>
                                            <SuiTypography
                                                variant={"subtitle2"}
                                                fontWeight='regular'
                                                textColor='text'>
                                                {description}
                                            </SuiTypography>
                                        </SuiBox>
                                    )}
                                </>
                            ) : (
                                header
                            )}
                        </SuiBox>
                        <SuiBox display='flex' justifyContent='center' p={3}>
                            {children}
                        </SuiBox>
                    </SuiBox>
                    <Footer />
                </Grid>
            </Grid>
        </PageLayout>
    );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
    header: "",
    title: "",
    description: "",
    color: "info",
    top: "8%",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    header: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node.isRequired,
};

export default CoverLayout;
