import { VALIDATIONEVENT } from "services/crudApiConfig";
import { EVENTADDER } from "services/crudApiConfig";
import { EVENTVALIDATOR } from "services/crudApiConfig";
import { CONSENTS } from "services/crudApiConfig";
import { VERIFICATIONEVENT } from "services/crudApiConfig";
import { PROFILE, COURSE } from "services/crudApiConfig";

// Backend should just id property for all domain objects
export const getId = (domainData, domain) => {
  switch (domain) {
    case PROFILE:
      return domainData.id;
    case COURSE:
      return domainData.id;
    case VERIFICATIONEVENT:
      return domainData.eventId;
    case VALIDATIONEVENT:
      return domainData.eventId;
    case CONSENTS:
      return domainData.Id;
    case EVENTADDER:
      return domainData.profile_id;
    case EVENTVALIDATOR:
      return domainData.profile_id;
    default:
      return domainData.id;
  }
};
export default getId;
