const INITIAL_STATE = {
  error: null,
  oid: null,
  route: false,
  orientationLists: null,
  orientation_submenu: null,
  orientation: null,
  questionLists: null,
  value: null,
};

const OrientationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ORIENTATION":
      return {
        ...state,
        oid: action.payload,
      };
    case "SET_TRUE":
      return {
        ...state,
        route: action.payload,
      };
    case "LOAD_VALUE":
      return {
        ...state,
        value: action.payload,
      };
    case "SET_ORIENTATION_LISTS":
      return {
        ...state,
        orientationLists: action.payload,
      };
    case "SET_ORIENTATION_MENU":
      return {
        ...state,
        orientation_submenu: action.payload,
      };

    case "SET_ORIENTATION_DETAILS":
      return {
        ...state,
        orientation: action.payload,
      };
    case "SET_QUESTION_LISTS":
      return {
        ...state,
        questionLists: action.payload,
      };

    case "SET_ERROR_DETAILS":
      return {
        ...state,
        error: action.payload,
      };

    case "SHOW_MODAL":
      return {
        ...state,
        show: action.payload,
      };

    default:
      return state;
  }
};

export default OrientationReducer;
