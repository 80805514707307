/* eslint-disable import/first */
import React from "react";

const CreateTicketPage = React.lazy(() =>
  import(
    "components/required-components/ticket-support-component/CreateTicketComponent"
  )
);
const EditTicketComponent = React.lazy(() =>
  import(
    "components/required-components/ticket-support-component/EditTicketComponent"
  )
);
const IndividualTicketComponent = React.lazy(() =>
  import(
    "components/required-components/ticket-support-component/IndividualTicketComponent"
  )
);

// const IndividualDepartmentDisplay = React.lazy(() =>
//   import(
//     "components/required-components/department-component/IndividualDepartmentDisplay"
//   )
// );
// const EditDepartmentComponent = React.lazy(() =>
//   import(
//     "components/required-components/department-component/EditDepartmentComponent"
//   )
// );

// const IndividualTeamPage = React.lazy(() =>
//   import("components/required-components/team-component/IndividualTeamPage")
// );
// const TeamInvitePage = React.lazy(() =>
//   import("components/required-components/team-component/TeamInvitePage")
// );

const CompanyDetailsForm = React.lazy(() =>
  import("components/required-components/company-component/CompanyDetailsForm")
);

const EditCompanyDetailsForm = React.lazy(() =>
  import(
    "components/required-components/company-component/ParentEditCompanyDetails"
  )
);

// const EditEducationDetails = React.lazy(() =>
//   import(
//     "components/required-components/rookie/onboard-component/EditEducationDetails"
//   )
// );

const EditPersonalDetails = React.lazy(() =>
  import(
    "components/required-components/rookie/onboard-component/EditPersonalDetails"
  )
);

// const EditWorkDetails = React.lazy(() =>
//   import(
//     "components/required-components/rookie/onboard-component/EditWorkDetails"
//   )
// );

// const IndividualOnboardForm = React.lazy(() =>
//   import(
//     "components/required-components/rookie/onboard-component/IndividualOnboardForm"
//   )
// );

// const MultipleCandidatesOnboardForm = React.lazy(() =>
//   import(
//     "components/required-components/rookie/onboard-component/MultipleCandidatesOnboardForm"
//   )
// );

// const OnboardForm = React.lazy(() =>
//   import(
//     "components/required-components/rookie/onboard-component/OnboardForm/OnboardForm"
//   )
// );

// const AddQuestionsComponent = React.lazy(() =>
//   import(
//     "components/required-components/rookie/orientation-component/edit-section/AddQuestionsComponent"
//   )
// );

// const AddQuestionsForTraining = React.lazy(() =>
//   import(
//     "components/required-components/rookie/training-component/edit-training/AddQuestionsForTraining"
//   )
// );

// const EditQuestionsComponent = React.lazy(() =>
//   import(
//     "components/required-components/rookie/orientation-component/edit-section/EditQuestionsComponent"
//   )
// );

// const EditOrientationComponent = React.lazy(() =>
//   import(
//     "components/required-components/rookie/orientation-component/ParentOrientationEditComponent"
//   )
// );

// const OrientationForm = React.lazy(() =>
//   import(
//     "components/required-components/rookie/orientation-component/OrientationForm"
//   )
// );

const CreateRoleComponent = React.lazy(() =>
  import(
    "components/required-components/rookie/role-component/CreateRoleComponent"
  )
);

const EditRoleComponent = React.lazy(() =>
  import(
    "components/required-components/rookie/role-component/EditRoleComponent"
  )
);

const IndividualRoleDisplay = React.lazy(() =>
  import(
    "components/required-components/rookie/role-component/IndividualRoleDisplay"
  )
);

// const EditTrainingForm = React.lazy(() =>
//   import(
//     "components/required-components/rookie/training-component/ParentTrainingEditComponent"
//   )
// );

// const TrainingForm = React.lazy(() =>
//   import(
//     "components/required-components/rookie/training-component/TrainingForm"
//   )
// );

// const TrainingsComponent = React.lazy(() =>
//   import(
//     "components/required-components/rookie/training-component/TrainingsComponent"
//   )
// );

const EditUserForm = React.lazy(() =>
  import(
    "components/required-components/user-component/ParentEditUserComponent"
  )
);

const IndividualUserDisplay = React.lazy(() =>
  import("components/required-components/user-component/IndividualUserDisplay")
);

const UserForm = React.lazy(() =>
  import("components/required-components/user-component/UserForm")
);
// const OrientationViewPage = React.lazy(() =>
//   import("pages/rookie/OrientationViewPage")
// );

// const TrainingsPage = React.lazy(() => import("pages/rookie/TrainingsPage"));

// import lettersRoutesConfig from "./lettersRoutesConfig";

const routes = [
  // {
  //   path: "/onboard",
  //   type: "collapse",
  //   component: OnboardForm,
  //   exact: true,
  // },
  // {
  //   path: "/multiple-onboards",
  //   type: "collapse",
  //   component: MultipleCandidatesOnboardForm,
  //   exact: true,
  // },
  // {
  //   path: "/individual-onboard",
  //   type: "collapse",
  //   component: IndividualOnboardForm,
  //   exact: true,
  // },
  {
    path: "/individual-onboard/edit-personal-details",
    type: "collapse",
    component: EditPersonalDetails,
    exact: true,
  },
  // {
  //   path: "/individual-onboard/edit-education-details",
  //   type: "collapse",
  //   component: EditEducationDetails,
  //   exact: true,
  // },
  // {
  //   path: "/individual-onboard/edit-work-details",
  //   type: "collapse",
  //   component: EditWorkDetails,
  //   exact: true,
  // },
  {
    path: "/edit-company/:id",
    type: "collapse",
    component: EditCompanyDetailsForm,
    exact: true,
    role: "default",
  },
  {
    path: "/create-company",
    type: "collapse",
    component: CompanyDetailsForm,
    exact: true,
    role: "peopleHub",
  },
  {
    path: "/talent/roles/create-role",
    type: "collapse",
    component: CreateRoleComponent,
    exact: true,
    role: "peopleHub",
  },
  {
    path: "/view-role/:rid",
    type: "collapse",
    component: IndividualRoleDisplay,
    exact: true,
    role: "peopleHub",
  },
  {
    path: "/edit-role/:id",
    type: "collapse",
    component: EditRoleComponent,
    exact: true,
    role: "peopleHub",
  },
  {
    path: "/create-user",
    type: "collapse",
    component: UserForm,
    exact: true,
    role: "peopleHub",
  },
  {
    path: "/view-user/:uid",
    type: "collapse",
    component: IndividualUserDisplay,
    exact: true,
    role: "peopleHub",
  },
  {
    path: "/edit-user/:id",
    type: "collapse",
    component: EditUserForm,
    exact: true,
    role: "peopleHub",
  },
  // {
  //   path: "/orientation",
  //   type: "collapse",
  //   component: OrientationForm,
  //   exact: true,
  //   role: "peopleHub",
  // },
  // {
  //   path: "/edit-orientation/:id",
  //   type: "collapse",
  //   component: EditOrientationComponent,
  //   exact: true,
  // },
  // {
  //   path: "/edit-question/:id/:title/:type",
  //   type: "collapse",
  //   component: EditQuestionsComponent,
  //   exact: true,
  // },
  // {
  //   path: "/add-questions/:id/:title/:type",
  //   type: "collapse",
  //   component: AddQuestionsComponent,
  //   exact: true,
  // },
  // {
  //   path: "/add-training-questions/:id/:type",
  //   type: "collapse",
  //   component: AddQuestionsForTraining,
  //   exact: true,
  // },
  // {
  //   path: "/orientation-view",
  //   type: "collapse",
  //   component: OrientationViewPage,
  //   exact: true,
  // },
  // {
  //   path: "/create-training/:id",
  //   type: "collapse",
  //   component: TrainingForm,
  //   exact: true,
  // },
  // {
  //   path: "/edit-training/:id",
  //   type: "collapse",
  //   component: EditTrainingForm,
  //   exact: true,
  // },
  // {
  //   path: "/trainings",
  //   type: "collapse",
  //   component: TrainingsPage,
  //   exact: true,
  // },
  // {
  //   path: "/view-trainings/:id",
  //   type: "collapse",
  //   component: TrainingsComponent,
  //   exact: true,
  // },
  // {
  //   path: "/hiring-section/onboarding/trainings/view-trainings/:id",
  //   type: "collapse",
  //   component: TrainingsComponent,
  //   exact: true,
  // },
  // {
  //   path: "/team/:title/:id",
  //   type: "collapse",
  //   component: IndividualTeamPage,
  //   exact: true,
  // },
  // {
  //   path: "/department/:id",
  //   type: "collapse",
  //   component: IndividualDepartmentDisplay,
  //   exact: true,
  // },
  // {
  //   path: "/edit-department/:id",
  //   type: "collapse",
  //   component: EditDepartmentComponent,
  //   exact: true,
  // },
  {
    path: "/create-tickets",
    type: "collapse",
    component: CreateTicketPage,
    exact: true,
  },
  {
    path: "/support/view-ticket/:id",
    type: "collapse",
    component: IndividualTicketComponent,
    exact: true,
  },
  {
    path: "/support/edit-ticket/:id",
    type: "collapse",
    component: EditTicketComponent,
    exact: true,
  },
];
// const finalRoutes = routes.concat(lettersRoutesConfig);
export default routes;
