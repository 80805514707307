import { ButtonLabels } from "../../../data/rookie/ButtonLabels"
import { Labels } from "../../../data/rookie/Labels"
import { TableLabels } from "../../../data/rookie/TableLabels"
import { TextLabels } from "../../../data/rookie/TextLabels"

const INITIAL_STATE = {
    labels: Labels,
    buttonLabels: ButtonLabels,
    tableLabels: TableLabels,
    textLabels: TextLabels,
}
const LabelsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_LABELS':
            return {
                ...state,
                labels: action.payload
            }
        
        case 'SET_BUTTON_LABELS':
            return {
                ...state,
                buttonLabels: action.payload
            }
        
        case 'SET_TABLE_LABELS':
            return {
                ...state,
                tableLabels: action.payload
            }
        
        case 'SET_TEXT_LABELS':
            return {
                ...state,
                textLabels: action.payload
            }
    
        case 'SET_ERROR_DETAILS':
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default LabelsReducer
