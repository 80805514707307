const INITIAL_STATE = {
  status: null,
};
const ErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `POST_DOMAIN_REJECTED`:
      return {
        ...state,
        status: action.error?.response?.status ?? action.error,
      };
    case `GET_LIST_DOMAIN_REJECTED`:
      return {
        ...state,
        status: action.error?.response?.status ?? action.error,
      };

    case `UPDATE_DOMAIN_REJECTED`:
      return {
        ...state,
        status: action.error?.response?.status ?? action.error,
      };

    default:
      return state;
  }
};

export default ErrorReducer;
