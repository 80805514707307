const INITIAL_STATE = {
  currentUser: null,
  oid: null,
  tid: null,
  userTokenValues: null,
  error: null,
};
const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
      };

    case "SET_USER_TOKEN_VALUES":
      return {
        ...state,
        userTokenValues: action.payload,
      };

    case "SET_TRAINING_ID":
      return {
        ...state,
        tid: action.payload,
      };

    case "SET_ERROR_DETAILS":
      return {
        ...state,
        error: action.payload,
      };

    case "SET_ORIENTATION":
      return {
        ...state,
        oid: action.payload,
      };

    case "SET_TRAINING":
      return {
        ...state,
        tid: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
