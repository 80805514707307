// eslint-disable-next-line
import { Snackbar } from "components/toolkit";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { errorSelector } from "selectors/labelSelectors";

const ErrorDisplaySection = () => {
  const errorState = useSelector(errorSelector);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState({
    status: "",
    state: false,
  });
  const history = useHistory();
  useEffect(() => {
    if (errorState) {
      setShowError({ status: errorState, state: true });
    } else {
      setShowError({ status: errorState, state: false });
    }
  }, [history, errorState]);

  const toggleSnackbar = () => {
    dispatch({
      type: `POST_DOMAIN_REJECTED`,
      error: null,
    });
    setShowError({ status: "", state: false });
  };

  return (
    <Snackbar
      color="error"
      icon="notifications"
      title="We are facing some issue."
      content="Please try again after some time"
      open={showError.state}
      close={toggleSnackbar}
    />
  );
};

export default ErrorDisplaySection;
