import React from "react";
import SuiBox from "components/toolkit/SuiBox";
import {  Typography, Grid, Avatar } from "components/toolkit";

function RecordItem({ mb, text, logo, location }) {
  return (
    <SuiBox backgroundColor="white" borderRadius={16} p={1} mb={mb}>
      <Grid container spacing={1}>
        <Grid item sm={3} md={3} lg={3}>
          <SuiBox display="flex" justifyContent="center" alignItems="center">
            <Avatar
              src={logo}
              alt="profile-image"
              variant="rounded"
              size="lg"
              shadow="sm"
            />
          </SuiBox>
        </Grid>
        <Grid item sm={5} md={5} lg={5}>
          <SuiBox display="flex" justifyContent="center" alignItems="center">
            <Typography textColor="text" variant="button" fontWeight="regular">
              {text}
            </Typography>
          </SuiBox>
        </Grid>
        <Grid item sm={4} md={4} lg={4}>
          <SuiBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography
              textColor="primary"
              variant="button"
              fontWeight="regular"
            >
              {location}
            </Typography>
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default RecordItem;
