const INITIAL_STATE = {
  error: null,
  oid: null,
  trainingLists: null,
  trainingMenu: null,
  trainingSubMenu: null,
  training_workflow_list: null,
  training: null,
  value: null,
  route: null,
  endState: null,
};

const TrainingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ORIENTATION":
      return {
        ...state,
        oid: action.payload,
      };
    case "SET_TRUE":
      return {
        ...state,
        route: action.payload,
      };
    case "LOAD_VALUE":
      return {
        ...state,
        value: action.payload,
      };
    case "SET_ROUTE":
      return {
        ...state,
        endState: action.payload,
      };
    case "SET_TRAINING_LISTS":
      return {
        ...state,
        trainingLists: action.payload,
      };
    case "SET_TRAINING_MENU":
      return {
        ...state,
        trainingMenu: action.payload,
      };

    case "SET_TRAINING_SUBMENU":
      return {
        ...state,
        trainingSubMenu: action.payload,
      };
    case "SET_TRAINING_WORKFLOW_MENU":
      return {
        ...state,
        training_workflow_list: action.payload,
      };

    case "SET_ACTUAL_TRAINING":
      return {
        ...state,
        training: action.payload,
      };

    case "SET_ERROR_DETAILS":
      return {
        ...state,
        error: action.payload,
      };

    case "SHOW_MODAL":
      return {
        ...state,
        show: action.payload,
      };

    default:
      return state;
  }
};

export default TrainingReducer;
