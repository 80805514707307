import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
// import SuiTypography from "components/toolkit/SuiTypography";
import { Typography } from "components/toolkit";
import { Box } from "components/toolkit";
import { Button } from "components/toolkit";

const ModalLayout = ({ showModal, toggle, handleClick }) => {
    const ButtonLabels = useSelector((state) => state.labels.buttonLabels);
    const TextLabels = useSelector((state) => state.labels.textLabels);

    return (
        <Modal isOpen={showModal} toggle={toggle} className=''>
            <ModalBody>
                <Typography
                    sx={{ textAlign: "center" }}
                    variant='h5'
                    textColor='secondary'>
                    {TextLabels.logout.label}
                </Typography>
            </ModalBody>
            <ModalFooter>
                <Box
                    display='flex'
                    width='100%'
                    justifyContent='center'
                    gap={4}>
                    <Button buttonColor='primary' onClick={toggle}>
                        {ButtonLabels.cancel.label}
                    </Button>
                    <Button buttonColor='light' onClick={handleClick}>
                        {ButtonLabels.logout.label}
                    </Button>
                </Box>
            </ModalFooter>
        </Modal>
    );
};

export default ModalLayout;
