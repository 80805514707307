import React from "react";
import { Typography } from "components/toolkit";

export const MetaData = ({ label, color, pTop }) => {
    return (
        <Typography
            variant='caption'
            textColor={color ? color : "secondary"}
            fontSize={`${14 / 16}rem`}
            sx={{ pt: pTop }}>
            {label}
        </Typography>
    );
};

export const ActualData = ({
    data,
    pTop,
    color,
    fontWeight,
    fontSize,
    styleProps,
}) => {
    return (
        <Typography
            component='dt'
            fontWeight={fontWeight ? fontWeight : "medium"}
            textColor={color ? color : "text"}
            sx={{ pt: pTop, color, ...styleProps }}
            fontSize={`${fontSize ? fontSize : `${14 / 16}rem`}`}>
            {data}
        </Typography>
    );
};

export const FormLabelData = ({ label, text }) => {
    return (
        <Typography
            mt={0.2}
            component='label'
            variant='caption'
            fontWeight='bold'
            style={{ textAlign: text }}>
            {label}
        </Typography>
    );
};

export const CustomData = ({ data, isSpacing, color }) => {
    return (
        <Typography
            component='dt'
            fontWeight='light'
            textColor='text'
            fontSize={`${10 / 16}rem`}
            sx={{ pt: "0.2rem", pl: "1rem", color: color }}>
            {data}
        </Typography>
    );
};
