export const ButtonLabels = {
  login: {
    label: "Login",
    type: "submit",
  },
  dashboard: {
    startOboard: {
      label: "Start Onboarding",
    },
    onboard: {
      label: "Onboard Employee",
      type: "submit",
    },
    multipleOnboards: {
      label: "Onboard Employees",
      type: "submit",
    },
    view: {
      label: "View Candidates",
    },
  },
  role: {
    label: "Create",
    type: "submit",
  },
  general: {
    new: {
      label: "Add new",
    },
    submit: {
      label: "Submit",
      type: "submit",
    },
    AddQuestion: {
      label: "Add Questions",
      type: "button",
    },
    updateSubmit: {
      label: "Update Video Link",
      type: "submit",
    },
    save: {
      label: "Save",
      type: "submit",
    },
    close: {
      label: "Close",
    },
    logout: {
      label: "Logout",
    },
    switch: {
      label: "is_disabled",
      type: "switch",
    },
    create: {
      label: "Create",
      type: "submit",
    },
    update: {
      label: "Update",
      type: "submit",
    },
    updateWorkflow: {
      label: "Update Workflow",
      type: "button",
    },
    download: {
      label: "Download Format",
    },
  },
  company: {
    create: {
      label: "Create",
      type: "submit",
    },
    update: {
      label: "Update",
      type: "submit",
    },
    edit: {
      label: "Edit",
    },
    add: {
      label: "Add Company Details",
    },
    view: {
      label: "View Company Details",
    },
  },
  reset: {
    password: {
      label: "Reset Password",
    },
  },
  logout: {
    label: "Logout",
  },
  cancel: {
    label: "Cancel",
  },
  proceed: {
    label: "Proceed",
  },
  finish: {
    label: "Finish",
  },
  user: {
    edit: {
      label: "Update",
      type: "submit",
    },
  },
  orientation: {
    label: "Create Orientation Now",
  },
  offer: {
    add: "Add Issue Letters",
  },
  workflow: {
    create: "Create Workflow",
    update: "Update role",
  },
  action: {
    label: "Update Acion",
    type: "button",
  },
};
