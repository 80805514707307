import axios from 'axios';
import { companyId } from 'utils/constants';
import { api_requests } from '../../utils/rookie/ApiUrlsComponent';

export const setRoleList = (role) => ({
  type: 'SET_ROLE_LIST',
  payload: role,
});

export const setRole = (role) => ({
  type: 'SET_ROLE',
  payload: role,
});

export const setSkills = (skills) => ({
  type: 'SET_SKILLS',
  payload: skills,
});

export const setFilteredRole = (role) => ({
  type: 'SET_FILTERED_ROLE_LISTS',
  payload: role,
});

export const setError = (err) => ({
  type: 'SET_ERROR_DETAILS',
  payload: err,
});

export const loadModal = (type) => ({
  type: 'SHOW_MODAL',
  payload: type,
});

export const setRedirect = (type) => ({
  type: 'SET_REDIRECT',
  payload: type,
});

export const getRoleList = (value) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
    params: {
      q: { list: value, [companyId]: user.currentUser[companyId] },
    },
  };
  axios
    .get(api_requests.roleList, config)
    .then((response) => {
      // console.log();
      let rolesList = response.data;
      dispatch(setRoleList(rolesList));
      // dispatch(setCurrentUser({ token: token, account_id: aid, employee_name: response.data.first_name }))
    })
    .catch((err) => setError(err));
};

export const getSkills = () => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
    params: {
      q: { list: true },
    },
  };
  axios
    .get(api_requests.skillsList, config)
    .then((response) => {
      let skillsList = response.data;
      dispatch(setSkills(skillsList));
    })
    .catch((err) => setError(err));
};

export const addSkills = (data) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };
  axios
    .post(api_requests.skills, data, config)
    .then((response) => {
      // console.log(response.data);
      dispatch(getSkills());
    })
    .catch((err) => setError(err));
};

export const getPaginatedRolesList =
  (url, type, offset) => (dispatch, getState) => {
    const { user } = getState();

    if (type === 'paginated') {
      const newConfig = {
        headers: {
          Authorization: user.currentUser.token,
        },
        params: {
          limit: 10,
          offset: offset,
          [companyId]: user.currentUser[companyId],
        },
      };
      axios
        .get(api_requests.roleList, newConfig)
        .then((response) => {
          let rolesList = response.data;
          dispatch(setRoleList(rolesList));
        })
        .catch((err) => setError(err));
    } else {
      const config = {
        headers: {
          Authorization: user.currentUser.token,
        },
      };
      axios
        .get(url, config)
        .then((response) => {
          let rolesList = response.data;
          dispatch(setRoleList(rolesList));
        })
        .catch((err) => setError(err));
    }
  };

export const getRole = (id) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };
  axios
    .get(api_requests.role + id, config)
    .then((response) => {
      dispatch(setRole(response.data));
    })
    .catch((err) => setError(err));
};

export const getFilteredRoleList = (value) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
    params: {
      q: { list: value, [companyId]: user.currentUser[companyId] },
    },
  };
  axios
    .get(api_requests.roleList, config)
    .then((response) => {
      // console.log();
      let rolesList = response.data;
      rolesList.roles = rolesList.roles.map((item) => ({
        value: item.role_id,
        label: item.role_title,
      }));
      // console.log(rolesList.roles);
      // console.log(rolesList.roles);
      dispatch(setFilteredRole(rolesList.roles));
      // dispatch(setCurrentUser({ token: token, account_id: aid, employee_name: response.data.first_name }))
    })
    .catch((err) => setError(err));
};

export const postCreateRole = (data) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };
  // console.log(config);
  axios
    .post(api_requests.role, data, config)
    .then((response) => {
      dispatch(getRoleList());
      dispatch(setRedirect(true));
    })
    .catch((err) => {
      dispatch(setError(err.response.data.detail));
      setTimeout(() => {
        dispatch(setError(null));
      }, 6000);
    });
};

export const updateRole = (data, id) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };
  axios
    .put(api_requests.role + id + '/', data, config)
    .then((response) => {
      // console.log(response);
      dispatch(getRoleList());
      dispatch(setRedirect(true));
    })
    .catch((err) => {
      dispatch(setError(err.response.data.detail));
      setTimeout(() => {
        dispatch(setError(null));
      }, 6000);
    });
};

export const deleteRole = (id) => (dispatch, getState) => {
  const { user } = getState();
  const config = {
    headers: {
      Authorization: user.currentUser.token,
    },
  };
  // console.log(config);
  axios
    .delete(api_requests.role + id + '/', config)
    .then((response) => {
      dispatch(getRoleList());
      dispatch(loadModal(false));
      // dispatch(setCurrentUser({ token: token, account_id: aid, employee_name: response.data.first_name }))
    })
    .catch((err) => {
      dispatch(setError(err.response.data.detail));
      setTimeout(() => {
        dispatch(setError(null));
      }, 6000);
    });
};
