// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";

function Home({ color, size }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>Home</title>
            <g id="Basic-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Rounded-Icons"
                    transform="translate(-1870.000000, -591.000000)"
                >
                    <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
                        <g id="document" transform="translate(154.000000, 300.000000)">
                            <path
                                d="M18.3813 9.53992L16.75 7.90979V3.91492C16.75 3.29617 16.2438 2.78992 15.625 2.78992H14.5C13.8812 2.78992 13.375 3.29617 13.375 3.91492V4.53704L11.125 2.28929C10.8179 1.99904 10.5366 1.66492 10 1.66492C9.46337 1.66492 9.18213 1.99904 8.875 2.28929L1.61875 9.53992C1.26775 9.90554 1 10.1722 1 10.6649C1 11.2983 1.486 11.7899 2.125 11.7899H3.25V18.5399C3.25 19.1587 3.75625 19.6649 4.375 19.6649H6.625C7.24632 19.6649 7.75 19.1612 7.75 18.5399V14.0399C7.75 13.4212 8.25625 12.9149 8.875 12.9149H11.125C11.7438 12.9149 12.25 13.4212 12.25 14.0399V18.5399C12.25 19.1612 12.1912 19.6649 12.8125 19.6649H15.625C16.2438 19.6649 16.75 19.1587 16.75 18.5399V11.7899H17.875C18.514 11.7899 19 11.2983 19 10.6649C19 10.1722 18.7323 9.90554 18.3813 9.53992Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

// Setting default values for the props of Office
Home.defaultProps = {
    color: "dark",
    size: "16px",
};

// Typechecking props for the Office
Home.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "white",
    ]),
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Home;