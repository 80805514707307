import React from "react";
import { Box, Typography, Badge } from "components/toolkit";
const BadgeContent = ({
  badgeLabel,
  comment,
  color,
  alignItems,
  size,
  variantState,
  fontSize,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      alignItems={alignItems ? alignItems : "flex-end"}
    >
      <Badge
        size={size ? size : "small"}
        color={color ? color : "success"}
        badgeContent={badgeLabel}
        variant={variantState ? variantState : "gradient"}
        container
      />
      <Typography fontSize={fontSize ?? "1rem"} fontWeight="light">
        {comment}
      </Typography>
    </Box>
  );
};

export default BadgeContent;
