export const Labels = {
  recruit: {
    name: {
      name: "name",
      placeholder: "Name",
      label: "Name *",
      type: "text",
    },
    email: {
      type: "email",
      label: "Email *",
      name: "email",
      placeholder: "your@email.com",
    },
    role: {
      name: "role",
      placeholder: "Select role",
      label: "Role *",
      type: "select",
    },
    expiryDays: {
      name: "token_duration",
      placeholder: "Select days",
      label: "Link expires in *",
      type: "number",
    },
    workflow: {
      name: "workflow_id",
      placeholder: "Workflow",
      label: "Workflow *",
      type: "select",
    },
  },
  hr: {
    conducted: {
      name: "conducted",
      placeholder: "Select user",
      label: "Conducted by",
      type: "select",
    },
    date_time: {
      name: "date_time",
      placeholder: "date time",
      label: "Date time",
      type: "datetime-local",
    },
    radio: {
      name: "link_details",
      type: "radio",
    },
    location: {
      name: "location_details",
      placeholder: "Enter location details",
      label: "Location details",
      type: "textarea",
    },
    online_link: {
      name: "online_meeting_link",
      placeholder: "Enter online meeting link",
      label: "Online meeting link",
      type: "url",
    },
    instructions: {
      name: "instructions",
      placeholder: "Enter instructions",
      label: "Instructions",
      type: "textarea",
    },
    additional: {
      name: "additional_informantion",
      placeholder: "Enter additional information",
      label: "Additional information",
      type: "textarea",
    },
    comments: {
      name: "comment",
      placeholder: "Enter comments",
      label: "Comments",
      type: "textarea",
    },
    radio1: {
      name: "completed",
      type: "radio",
    },
  },
  modal: {
    choice1: {
      label: "Communication",
      name: "choice1"
    },
    choice2: {
      label: "Leadership",
      name: "choice2"
    },
    choice3: {
      label: "Management",
      name: "choice3"
    },
    choice4: {
      label: "Technical",
      name: "choice4"
    },
    choice5: {
      label: "Analytical",
      name: "choice5"
    },
    choice6: {
      label: "Presentation",
      name: "choice6"
    }
  },
  screener: {
    title: {
      name: "title",
      placeholder: "Enter screneer title",
      label: "Screneer title",
      type: "text",
    },
    question: {
      name: "title",
      placeholder: "Enter question",
      label: "Question",
      type: "text",
    },
    answer_type: {
      name: "answer_type",
      placeholder: "Select question type",
      label: "Question type",
      type: "select",
    },
    boolean: {
      name: "answer",
      placeholder: "Select answer",
      label: "Answer",
      type: "select",
    },
    yes_no: {
      label: "Yes/No",
    },
    number: {
      label: "Number",
    },
    yes: {
      label: "Yes",
    },
    no: {
      label: "No",
    },
    min: {
      name: "min",
      placeholder: "Enter number",
      label: "Min",
      type: "number",
    },
    max: {
      name: "max",
      placeholder: "Enter number",
      label: "Max",
      type: "number",
    },
  },
  test: {
    title: {
      name: "title",
      placeholder: "Enter test title",
      label: "Test title",
      type: "text",
    },
    duration: {
      name: "duration_per_question",
      placeholder: "Enter duration per question",
      label: "Duration per question (in sec)",
      type: "number",
    },
    question: {
      name: "title",
      placeholder: "Select question",
      label: "Question",
      type: "text",
    },
    file: {
      name: "pdf_upload",
      placeholder: "Choose pdf/jpeg/png",
      label: "file Link",
      type: "url",
    },
    video: {
      name: "video_link",
      placeholder: "Enter video link",
      label: "Video Link",
      type: "url",
    },
    answer: {
      label: "Choices",
    },
    radio: {
      name: "answer",
      type: "radio",
    },
    option1: {
      name: "choice1",
      placeholder: "option1",
      type: "text",
    },
    option2: {
      name: "choice2",
      placeholder: "option2",
      type: "text",
    },
    option3: {
      name: "choice3",
      placeholder: "option3",
      type: "text",
    },
    option4: {
      name: "choice4",
      placeholder: "option4",
      type: "text",
    },
  },
  interview_workflow: {
    workflow: {
      name: "workflow_title",
      placeholder: "Interview workflow title",
      label: "Interview workflow title",
      type: "text",
    },
    role: {
      name: "role",
      placeholder: "Select role",
      label: "Role",
      type: "select",
    },
    round_name: {
      name: "round_name",
      placeholder: "Enter round name",
      label: "Round name",
      type: "text",
    },
    round_type: {
      name: "round_type",
      placeholder: "Select round type",
      label: "Round type",
      type: "select",
    },
    round_order: {
      name: "round_order",
      placeholder: "Enter order number",
      label: "Round order",
      type: "number",
    },
    screener: {
      name: "screener",
      placeholder: "Select Screener",
      label: "Screener",
      type: "select",
    },
    test: {
      name: "test",
      placeholder: "Select test",
      label: "Test",
      type: "select",
    },
    users: {
      name: "users",
      placeholder: "Select users",
      label: "Users",
      type: "select",
    },
    public_link: {
      name: "public_link",
      placeholder: "Public link",
      label: "Public link",
      type: "text",
    },
  },
  errorLabels: {
    roleError: "Please select the role and proceed",
    linkError: "Please choose any one of the Link options",
    questionsError: "Please fill all the fields corectly",
    answerError: "Please choose the answer and proceed",
    workflowError: "Looks like there is a link connection missing. Please check and save",
    DateError: "Please edit the date and approve",
    skillsError: "Please select the role and skills to continue",
    errorMsg: "Please fill all the fields corectly"
  },
};
