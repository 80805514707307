import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import { BrowserRouter } from "react-router-dom";
import "./styles/reduction.scss";
// import { ThemeProvider } from "@material-ui/core/styles"
import { SoftUIControllerProvider } from "context";
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import KwicklyThemeProvider from "providers/KwicklyThemeProvider";

ReactDOM.render(
  <SoftUIControllerProvider>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <KwicklyThemeProvider>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <BrowserRouter basename="/admin">
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </BrowserRouter>
        </KwicklyThemeProvider>
      </Provider>
    </StyledEngineProvider>
  </SoftUIControllerProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
