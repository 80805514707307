const INITIAL_STATE = {
  projectId: null,
  conversationId: null,
  latestConversation: null,
  messageHistory: null,
};
const ChatBotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_COVERSATION_ID":
      return {
        ...state,
        conversationId: action.payload,
      };
    case "SAVE_LATEST_CONVERSATION":
      return {
        ...state,
        latestConversation: action.payload,
      };
    case "SAVE_MESSAGE_HISTORY":
      return {
        ...state,
        messageHistory: action.payload,
      };
    case "CLEAR_CHATBOT_DETAILS":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default ChatBotReducer;
