/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Fragment } from "react";
import { forwardRef } from "react";

// clsx is a utility for constructing className string conditionally
import clsx from "clsx";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Avatar from "@mui/material/Avatar";

// Custom styles for SuiAvatar
import styles from "components/toolkit/SuiAvatar/styles";
import { Tooltip } from "@mui/material";

function stringAvatar(name) {
    if (!name) return {};
    const names = name.split(" ");
    const fName = names[0];
    const lName = names.length > 1 ? names[names.length - 1] : [""];
    return { children: `${fName[0].toUpperCase()}${lName[0].toUpperCase()}` };
}

const SuiAvatar = forwardRef(
    (
        {
            backgroundColor,
            size,
            boxShadow,
            customClass,
            onUserPage,
            fullName,
            showTooltip,
            ...rest
        },
        ref
    ) => {
        const classes = styles({ boxShadow, backgroundColor, onUserPage });
        const Container = showTooltip ? Tooltip : Fragment;
        const getContainerProps = () => {
            return showTooltip
                ? {
                      placement: "top",
                      title: fullName,
                  }
                : {};
        };
        return (
            <Container {...getContainerProps()}>
                <Avatar
                    ref={ref}
                    {...rest}
                    {...stringAvatar(fullName)}
                    className={clsx(classes.suiAvatar, customClass, {
                        [classes[`suiAvatar_${size}`]]: size,
                        [classes.suiAvatar_boxShadow]: boxShadow !== "none",
                    })}></Avatar>
            </Container>
        );
    }
);

// Setting default values for the props of SuiAvatar
SuiAvatar.defaultProps = {
    backgroundColor: "transparent",
    size: "md",
    boxShadow: "none",
    customClass: "",
};

// Typechecking props for the SuiAvatar
SuiAvatar.propTypes = {
    backgroundColor: PropTypes.oneOf([
        "transparent",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    boxShadow: PropTypes.oneOf([
        "none",
        "xs",
        "sm",
        "regular",
        "lg",
        "xl",
        "xxl",
        "inset",
    ]),
    customClass: PropTypes.string,
};

export default SuiAvatar;
