const INITIAL_STATE = {
  asociated_company_list: null,
  error: null,
};
const CompanyListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ASSOCIATED_COMPANY_LIST":
      return {
        ...state,
        asociated_company_list: action.payload,
      };

    default:
      return state;
  }
};

export default CompanyListReducer;
