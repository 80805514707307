export const TableLabels = {
  candidate: {
    name: "Name",
    email: "Email",
    phone: "Phone",
    role: "Role",
    gender: "Gender",
    dob: "Date of Birth",
    applied: "Applied",
    interview_workflow: "Interview Workflow",
    fields: "Fields",
    expiry: "Expiry Days",
    status: "Status",
    resume: "Resume",
    cover: "Cover Video",
  },
  screener: {
    name: "Name of Screener",
    questions: "No of Questions",
  },
  test: {
    name: "Test Title",
    duration: "Duration per Question",
    questions: "No of Questions",
  },
  interview_workflow: {
    name: "Interview Workflow Title",
  },
  rounds: {
    name: "Round Name",
    type: "Round Type",
    screener: "Screening Questions",
  },
};
