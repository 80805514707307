import React, { useState } from "react";
import { Box, Typography } from "components/toolkit";

const CardHeadingComponent = ({ heading, sideHeading, isFlex }) => {
  const [actualHeading, setActualHeading] = useState(heading);
  const furledTitle = (heading) => {
    if (heading.length < 15) {
      return heading;
    }
    return `${heading.substr(0, 15)}...`;
  };
  // const mediaQuery = window.matchMedia("(max-width: 600px)");
  // const handleMobileChange = (e, heading) => {
  //   if (e.matches) {
  //     furledTitle(heading);
  //   } else return heading;
  // };
  // console.log(mediaQuery.matches);
  // mediaQuery.addListener(() => handleMobileChange(mediaQuery, heading));
  const handleSizeChange = () => {
    if (window.innerWidth < 500) {
      const newHeading = furledTitle(actualHeading);
      setActualHeading(newHeading);
    } else if (window.innerWidth > 500) {
      setActualHeading(heading);
    }
  };
  window.addEventListener("resize", handleSizeChange);
  return (
    <Box
      display={!isFlex && "flex"}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography variant="h6" fontWeight="regular">
        {/* {mediaQuery.matches ? furledTitle(heading) : heading} */}
        {/* {handleMobileChange(mediaQuery, heading)} */}
        {actualHeading}
      </Typography>
      {/* <Typography variant="h6" fontWeight="regular">
        {sideHeading}
      </Typography> */}
      <Typography
        component="dt"
        fontWeight="regular"
        textColor={"text"}
        fontSize={`${12 / 16}rem`}
      >
        {sideHeading ?? ""}
      </Typography>
    </Box>
  );
};

export default CardHeadingComponent;
