import React from "react";
import "./NotAuthorizedPage.module.css";
import { Card, CardContent } from "components/toolkit";

const NotAuthorizedPage = () => {
  return (
    <div
      className="error-message-container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "85vh",
      }}
    >
      <Card>
        <CardContent>
          <div className="w3-display-middle">
            <h1 className="w3-jumbo w3-animate-top w3-center">
              <code>Access Denied</code>
            </h1>
            <hr
              className="w3-border-white w3-animate-left"
              style={{ margin: "auto", width: "50%" }}
            />
            <h3 className="w3-center w3-animate-right">
              You dont have permission to view this site.
            </h3>
            <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
            <h6 className="w3-center w3-animate-zoom">
              <strong>Error Code</strong>: 403 forbidden
            </h6>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NotAuthorizedPage;
