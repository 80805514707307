export const TextLabels = {
  header: {
    recruit: {
      label: "Candidate Details",
      candidate: "Recruit Candidate",
      view: "Dashboard",
    },
    screener: {
      add: "Create Screener",
      edit: "Update Screener",
      view: "Screeners",
    },
    test: {
      add: "Create Test",
      edit: "Update Test",
      view: "Tests",
    },
    interview: {
      label: "Interview Workflow",
    },
  },
  recruit: {
    screener: {
      view: "Screener",
      score: "Score",
      candidate_answer: "Candidate answer",
      required_answer: "Required answer",
    },
    test: {
      aptitude: "Aptitude Test",
      score: "Score",
      candidate_answer: "Candidate answer",
      required_answer: "Required answer",
    },
    form: {
      title: "Candidate has been created successfully.",
    },
    activity: "Activity Log",
  },
  delete: {
    question: "Are you sure you want to delete this Question?",
    screener: "Are you sure you want to delete this Screener?",
    test: "Are you sure you want to delete this Test?",
    candidate: "Are you sure you want to delete this Candidate?",
  },
  modal: {
    screener: "Screener has been created successfully.",
    question_update: "Question has been updated successfully.",
  },
  screener: {
    question: "Create Screening Questions",
    answer: "Required answer",
  },
  test: {
    question: "Create MCQS",
  },
  workflow: {
    errorText: "Please select the same workflow and proceed.",
  },
  human_interaction: {
    title: "Human Interaction Round",
    round_name: "Round Name",
    round_type: "Round Type",
    date_time: "Date & Time",
    conducted_by: "Conducted by",
    status: "Status",
    location: "Location Details",
    link: "Online Meet Link",
    instructions: "Instructions",
    additional_info: "Additional Information",
  },
  interview: {
    activate: "Activate the link",
    activated: "Activated",
  },
  alertDisplay: {
    title:
      "Please fill all the fields correctly and check if you have selected the answers. Given minimum of 2 questions.",
    subtitle:
      "Please fill all the fields correctly and check if you have selected the answers.",
    minMaxError: "Please fill all the fields correctly.",
  },
  publicLink: {
    label: "Public Link",
    heading: "Workflow",
    regenerate: "Re-generate the link",
    activated: "Activated",
  },
  overall_score: {
    overall: "Overall Score",
    not: "Not a good hire",
    average: "Average hire",
    good: "Good hire",
    excellent: "Excellent hire",
  },
  recruitWorkflow: {
    recruit: "Recruiting for ",
    heading: "Hiring Flow",
  },
};
