import CoverLayout from "layouts/authentication/components/CoverLayout";
import React, { useState } from "react";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";
import SuiInput from "components/toolkit/SuiInput";
import SuiButton from "components/toolkit/SuiButton";
import { Alert } from "@mui/material";
// import { useHistory } from "react-router-dom";
import { Spinner } from "components/toolkit";
import axios from "axios";
import { api_requests } from "utils/rookie/ApiUrlsComponent";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "redux/company-details/CompanyDetailActions";
import { getAuthConfig, DASHBOARD } from "routes/authRoutesConfig";
import styled from "styled-components";

const HoverLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;

function EmailLoginComponent() {
    // const history = useHistory();
    const [value, setValue] = useState({});
    const [error, setError] = useState(null);
    const [showOTPField, setShowOTPField] = useState(false);
    const [showOtpSpinner, setShowOtpSpinner] = useState();
    const [showOtpSuccess, setShowOtpSuccess] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const redirectHandler = () => {
        history.push(getAuthConfig(DASHBOARD).path);
    };

    const loginToken = useSelector((state) => state.user?.currentUser?.token);
    if (loginToken) {
        redirectHandler();
    }

    const handleChange = (event) => {
        event.persist();
        setValue((prev) => {
            return { ...prev, [event.target.name]: event.target.value };
        });
    };

    const navigateToTymelineLoginPage = () => {
        history.push("/tymeline-login");
    };

    // const handleFinalSubmit = (event) => {
    //   event.preventDefault();
    // };
    const handleFinalSubmit = (event) => {
        let user_data = { ...value };
        user_data.email = user_data.email.toLowerCase().trim().replace(" ", "");
        event.preventDefault();
        if (user_data.otp) {
            axios
                .post(api_requests.account_tl, user_data)
                .then((response) => {
                    // dispatch(
                    //   getAssociatedCompanyList(
                    //     response.data.account_id,
                    //     response.data.token
                    //   )
                    // );
                    const { token, account_id } = response.data;
                    dispatch(getCompany(token, account_id, true));
                    history.push("/user-dashboard");
                })
                .catch((err) => {
                    handleError(
                        err?.response?.data?.detail ??
                            "Something went wrong ! Try again later."
                    );
                    // setShowSpinner(false);
                    // handleError(err, "1");
                });
        } else {
            handleError("Please enter the OTP and Proceed");
        }
    };

    // const toggle = () => {
    //   setShowSuccessModal(false);
    //   setShowModalSpinner(true);
    // };

    const handleOTP = (e) => {
        // setShowOTPField(true);
        e.preventDefault();
        const uploadData = {};
        uploadData.email = value.email.toLowerCase().trim().replace(" ", "");

        if (value.email) {
            setShowOtpSpinner(true);
            axios
                .post(api_requests.getOTP, uploadData)
                // .post(api_requests.getOTP, value)
                .then((response) => {
                    setShowOTPField(true);
                    // setDisableLoginButton(false);
                    setShowOtpSuccess(true);
                    setTimeout(() => {
                        setShowOtpSuccess(false);
                    }, 5000);
                    setShowOtpSpinner(false);
                })
                .catch((err) => {
                    setShowOtpSpinner(false);
                    handleError("Please enter a registered email address");
                    // setShowSpinner(false);
                    // handleError(err, "1");
                });
        } else {
            handleError("Please enter a valid email address");
        }
    };

    const handleError = (msg) => {
        setError(msg);
        setTimeout(() => {
            setError(null);
        }, 3000);
    };

    return (
        <CoverLayout
            description='Enter your registered buiness email'
            image={curved9}
            fontSize='caption'>
            {/* <Modal isOpen={showSuccessModal} toggle={toggle}>
        <ModalBody>
          <div
            style={{
              height: "20vh",
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showModalSpinner ? (
              <Spinner className="mt-1 mr-3" size={50} color="secondary" />
            ) : (
              <div style={{ padding: "32px" }}>
                <SuiTypography variant="button">
                  Master key has been sent on your registered email address,
                  Please follow the instructions in the email.
                </SuiTypography>
              </div>
            )}
          </div>
        </ModalBody>
        {!showModalSpinner && (
          <ModalFooter>
            <ButtonComponent label="Okay" onClick={toggle} />
          </ModalFooter>
        )}
      </Modal> */}
            <SuiBox minWidth={300} width='100%' component='form' role='form'>
                <SuiBox mb={2}>
                    <SuiBox mb={1} ml={0.5}>
                        <SuiTypography
                            component='label'
                            variant='caption'
                            fontWeight='bold'>
                            Business Email
                        </SuiTypography>
                    </SuiBox>
                    <SuiInput
                        // pattern="\S+@\S+\.\S+"
                        type='text'
                        name='email'
                        data-testid='email'
                        value={value.email}
                        onChange={handleChange}
                        placeholder={"Enter your business email"}
                        required
                    />
                    {showOTPField && (
                        <SuiTypography
                            component='p'
                            variant='caption'
                            fontWeight='bold'
                            style={{ textAlign: "right", marginTop: "5px" }}
                            onClick={handleOTP}>
                            <span>
                                {showOtpSpinner && (
                                    <Spinner
                                        className='mr-2'
                                        size={10}
                                        color='primary'
                                    />
                                )}
                            </span>
                            <HoverLink>
                                {showOTPField
                                    ? "Regenerate OTP"
                                    : "Generate OTP"}
                            </HoverLink>
                        </SuiTypography>
                    )}
                </SuiBox>
                {showOTPField && (
                    <SuiBox mb={2}>
                        <SuiBox mb={1} ml={0.5}>
                            <SuiTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'>
                                OTP
                            </SuiTypography>
                        </SuiBox>
                        <SuiInput
                            // pattern="\S+@\S+\.\S+"
                            type='text'
                            name='otp'
                            value={value.otp}
                            onChange={handleChange}
                            placeholder={"Enter OTP"}
                            required
                        />
                    </SuiBox>
                )}
                <SuiBox mt={2} mb={1} textAlign='center'>
                    {!showOTPField ? (
                        <SuiButton
                            type='submit'
                            variant='gradient'
                            buttonColor='info'
                            fullwidth='true'
                            // disabled={disableLoginButton}
                            onClick={handleOTP}
                            data-testid='generate-key'>
                            Generate OTP
                        </SuiButton>
                    ) : (
                        <SuiButton
                            type='submit'
                            variant='gradient'
                            buttonColor='info'
                            fullwidth='true'
                            onClick={handleFinalSubmit}
                            data-testid='generate-key'>
                            Login
                        </SuiButton>
                    )}
                    <SuiBox
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'>
                        <SuiBox
                            mb={1}
                            display='flex'
                            justifyContent='center'
                            // onClick={handleScanQrCodeHandler}
                            sx={{ cursor: "pointer" }}></SuiBox>
                        <SuiBox
                            mb={1}
                            mt={2}
                            display='flex'
                            justifyContent='center'
                            onClick={navigateToTymelineLoginPage}
                            sx={{ cursor: "pointer" }}
                            textAlign='center'>
                            <SuiTypography
                                component='h6'
                                variant='caption'
                                fontWeight='bold'>
                                <HoverLink>Login via Tymeline ID</HoverLink>
                                <span
                                    style={{
                                        marginTop: "-3px",
                                        marginLeft: "2px",
                                    }}>
                                    {/* <VpnKeyIcon color="black" fontSize="small" /> */}
                                </span>
                            </SuiTypography>
                        </SuiBox>
                    </SuiBox>
                    {error !== null && (
                        <SuiBox mt={2}>
                            <Alert
                                severity='error'
                                isOpen={true}
                                sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.2rem",
                                }}>
                                {error}
                            </Alert>
                        </SuiBox>
                    )}

                    {showOtpSuccess && (
                        <SuiBox mt={2}>
                            <Alert
                                severity='success'
                                isOpen={true}
                                sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.2rem",
                                }}>
                                {
                                    "OTP has been sent to registered email address. Please enter OTP to proceed."
                                }
                            </Alert>
                        </SuiBox>
                    )}
                </SuiBox>
            </SuiBox>
        </CoverLayout>
    );
}

export default EmailLoginComponent;
