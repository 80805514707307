import { ButtonLabels } from "./../../../funnel/data/ButtonLabels"
import { Labels } from "./../../../funnel/data/Labels"
import { TableLabels } from "./../../../funnel/data/TableLabels"
import { TextLabels } from "./../../../funnel/data/TextLabels"

const INITIAL_STATE = {
    labels: Labels,
    buttonLabels: ButtonLabels,
    tableLabels: TableLabels,
    textLabels: TextLabels,
}
const FunnelLabelsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_FUNNEL_LABELS':
            return {
                ...state,
                labels: action.payload
            }
        
        case 'SET_FUNNEL_BUTTON_LABELS':
            return {
                ...state,
                buttonLabels: action.payload
            }
        
        case 'SET_FUNNEL_TABLE_LABELS':
            return {
                ...state,
                tableLabels: action.payload
            }
        
        case 'SET_FUNNEL_TEXT_LABELS':
            return {
                ...state,
                textLabels: action.payload
            }
    
        case 'SET_ERROR_DETAILS':
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default FunnelLabelsReducer
