import axios from "axios";
import { companyId } from "utils/constants";
import { api_requests } from "../../utils/rookie/ApiUrlsComponent";
import { setCurrentUser } from "../user/UserActions";
import { jwtDecode } from "jwt-decode";

export const setCompanyDetails = (user) => ({
    type: "SET_COMPANY_DETAILS",
    payload: user,
});

export const setCompanyBasicDetails = (user) => ({
    type: "SET_COMPANY_BASIC_DETAILS",
    payload: user,
});

export const setError = (err) => ({
    type: "SET_ERROR_DETAILS",
    payload: err,
});

export const clearCompanyDetails = (err) => ({
    type: "CLEAR_COMPANY_DETAILS",
    payload: err,
});

export const getCompanyBasicDetails = () => (dispatch, getState) => {
    // const { user } = getState();
    // const config = {
    //     headers: {
    //         "Authorization": user.currentUser.token
    //     }
    // }
    axios
        .get(api_requests.companybasic)
        .then((response) => {
            dispatch(setCompanyBasicDetails(response.data));
        })
        .catch((err) => setError(err));
};

export const getCompanyDetails = () => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };
    axios
        .get(api_requests.company + `${user.currentUser[companyId]}`, config)
        .then((response) => {
            dispatch(setCompanyDetails(response.data));
            // dispatch(getCompany(user.currentUser.token, user.currentUser.account_id, user.currentUser.is_superuser))
            // dispatch(setCurrentUser({ token: user.currentUser.token,
            //                             account_id: user.currentUser.account_id,
            //                             is_superuser: user.currentUser.is_superuser,
            //                             primary: response.data.theme_primary_colour,
            //                             secondary: response.data.theme_secondary_colour }))
        })
        .catch((err) => {
            dispatch(setError(err));
        });
    // .catch((err) => setError(err));
};

const getUserPayload = (companyData, accountId, token) => {
    const { theme_primary_colour, theme_secondary_colour, logo } = companyData;
    return {
        token: token,
        account_id: accountId,
        is_superuser: true,
        theme_primary_colour,
        theme_secondary_colour,
        [companyId]: companyData.company_id,
        logo,
    };
};

export const setCurrentUserDetails = (data, accountId, token) => (dispatch) => {
    getUserPayload(data, accountId, token);
};

export const getCompany = (token, account_id, is_superuser) => (dispatch) => {
    const config = {
        headers: {
            Authorization: token,
        },
    };
    let accessValue = [];
    let dashboardRoleType = "";
    try {
        const decodedValues = jwtDecode(token);
        // console.log(decodedValues);
        accessValue = decodedValues.access;
        dashboardRoleType = decodedValues?.dashboardRoleType;
    } catch (error) {
        console.log(error);
    }
    axios
        .get(api_requests.company, config)
        .then((response) => {
            dispatch(
                setCurrentUser({
                    token,
                    account_id,
                    access: accessValue,
                    dashboardRoleType,
                    is_superuser: is_superuser,
                    primary: response.data.theme_primary_colour,
                    secondary: response.data.theme_secondary_colour,
                    logo: response.data.logo,
                    [companyId]: response.data.company_id,
                })
            );
        })
        .catch((err) => {
            if (err.response.status === 404) {
                dispatch(
                    setCurrentUser({
                        token: token,
                        account_id: account_id,
                        is_superuser: is_superuser,
                    })
                );
            }
        });
};

export const updateCompanyDetails = (data, id) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };

    axios
        .put(api_requests.company + id + "/", data, config)
        .then((response) => {
            dispatch(getCompanyDetails());
            dispatch(
                setCurrentUser({
                    token: user.currentUser.token,
                    account_id: user.currentUser.account_id,
                    is_superuser: user.currentUser.is_superuser,
                    primary:
                        data.company.theme_primary_colour !== undefined
                            ? data.company.theme_primary_colour
                            : user.currentUser.theme_primary_colour,
                    secondary:
                        data.company.theme_secondary_colour !== undefined
                            ? data.company.theme_secondary_colour
                            : user.currentUser.theme_secondary_colour,
                    logo:
                        data.company.logo !== undefined
                            ? data.company.logo
                            : user.currentUser.logo,
                })
            );
        })
        .catch((err) => setError(err));
};
