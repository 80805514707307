import axios from "axios";
import createUUID from "utils/uuid";
import { api_requests } from "utils/rookie/ApiUrlsComponent";
import { useSelector } from "react-redux";
import { userSelector } from "selectors/user";

const useFileUpload = () => {
    const user = useSelector(userSelector);
    const uploadFile = (file) => {
        return new Promise(
            (resolve) => {
                // const returnResponse = () => {
                //     resolve({
                //         id: createUUID(),
                //         name: file.name,
                //     });
                // };
                // setTimeout(returnResponse, 2000);
                const formData = new FormData();
                formData.append("files", file);

                // Send the file to the server
                const config = {
                    headers: {
                        Authorization: user.token,
                        // "Content-Type": "multipart/form-data",
                    },
                };
                axios
                    .post(api_requests.uploadFile, formData, config)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data?.success) {
                            resolve(response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.log("File upload failed", error);
                    });
            },
            (reject) => {}
        );
    };

    const removeFile = (id) => {
        return new Promise((resolve) => {
            // const returnResponse = () => {
            //     resolve({
            //         id,
            //     });
            // };
            // setTimeout(returnResponse, 1000);
            const config = {
                headers: {
                    Authorization: user.token,
                },
            };
            axios
                .delete(api_requests.removeFile?.replace("{id}", id), config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data?.success) {
                        resolve({
                            id,
                        });
                    }
                })
                .catch((error) => {
                    console.log("File remove failed", error);
                });
        });
    };

    return {
        uploadFile,
        removeFile,
    };
};

export default useFileUpload;
