/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Grid, Avatar, Box, Typography } from "components/toolkit";

const ProfileHeader = () => {
  const profileBGImageURL =
    "https://firebasestorage.googleapis.com/v0/b/talkona.appspot.com/o/images%2FAutoTesting.jpg?alt=media&token=a42cd86c-5494-4490-9c21-bc4cca7cd63c";

  return (
    <>
      <Box position="relative" minHeight="8.75rem">
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="12.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.secondary.main, 0.2),
                rgba(gradients.secondary.state, 0.2)
              )}, url(${profileBGImageURL})`,
            // backgroundSize: "cover",
            backgroundSize: "contain",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
      </Box>
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar
              src={
                "https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=170667a&w=0&k=20&c=MRMqc79PuLmQfxJ99fTfGqHL07EDHqHLWg0Tb4rPXQc="
              }
              alt="profile-image"
              variant="rounded"
              size="xxl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h5" fontWeight="medium">
                {`Prashant Tomar`}
              </Typography>
              <Typography
                variant="button"
                textColor="text"
                fontWeight="regular"
              >
                {"prashant.tomar@quanscendence.com"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sx={{ ml: "auto" }}>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h6" fontWeight="medium">
                {"Tymeline ID"}
              </Typography>
              <Typography
                variant="button"
                textColor="primary"
                fontWeight="medium"
              >
                {`111-333-5555-Y`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProfileHeader;
