
const INITIAL_STATE = {
    overviewReports: null,
    overviewNumber: null,
    genderReports: null,
    setOnReports: null,
    comtoReports: null,
    setIntoComReports: null,
    skillsReports: null,
    rolesReports: null,
    ageReports: null,
    error: null
}
const ReportsDetailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_OVERVIEW_REPORTS':
            return {
                ...state,
                overviewReports: action.payload
            }
        case 'SET_OVERVIEW_NUMBER':
            return {
                ...state,
                overviewNumber: action.payload
            }
        case 'SET_GENDER_REPORTS':
            return {
                ...state,
                genderReports: action.payload
            }
        case 'SET_SETON_REPORTS':
            return {
                ...state,
                setOnReports: action.payload
            }
        case 'SET_COMTO_REPORTS':
            return {
                ...state,
                comtoReports: action.payload
            }

        case 'SET_SET_INTO_COM_REPORTS':
            return {
                ...state,
                setIntoComReports: action.payload
            }
        case 'SET_SKILSS_REPORTS':
            return {
                ...state,
                skillsReports: action.payload
            }
        case 'SET_ROLES_REPORTS':
            return {
                ...state,
                rolesReports: action.payload
            }
        case 'SET_AGE_REPORTS':
            return {
                ...state,
                ageReports: action.payload
            }
        case 'SET_ERROR_DETAILS':
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default ReportsDetailReducer
