import React, { useEffect } from "react";
import Footer from "./Footer";
import {
  DashboardNavbar,
  DashboardLayout as ToolkitDashboardLayout,
} from "components/toolkit";
import { useDispatch } from "react-redux";
import { setSideNavStatus } from "redux/rookie/workflow/WorkflowDetailActions";

export const DashboardLayout = ({
  loadSideNav,
  children,
  showHeader = true,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (loadSideNav) {
      dispatch(setSideNavStatus(false));
    }
  }, [loadSideNav, dispatch]);
  return (
    <ToolkitDashboardLayout showNav={showHeader}>
      {showHeader && <DashboardNavbar />}

      {children}
      <Footer />
    </ToolkitDashboardLayout>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => <>{children}</>; //<- dummy layout
