/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";

// @mui material components
import InputBase from "@mui/material/InputBase";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/toolkit/SuiBox";

// Custom styles for SuiInput
import styles from "components/toolkit/SuiInput/styles";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";

const SuiInput = forwardRef(
    (
        {
            size,
            withIcon,
            error,
            success,
            customClass,
            disabled,
            required,
            ...rest
        },
        ref
    ) => {
        let template;
        const [controller] = useSoftUIController();
        const { direction } = controller;
        const classes = styles({
            size,
            error,
            success,
            withIcon,
            direction,
            disabled,
        });
        if (withIcon.icon && withIcon.direction === "left") {
            template = (
                <SuiBox
                    ref={ref}
                    customClass={clsx(classes.suiInputIcon, customClass)}>
                    <SuiBox customClass={classes.suiInputIcon_right}>
                        <Icon
                            className={classes.suiInputIcon_icon}
                            color={withIcon.color}
                            fontSize='small'>
                            {withIcon.component}
                        </Icon>
                    </SuiBox>
                    <InputBase
                        inputRef={ref}
                        {...rest}
                        disabled={disabled}
                        required={required}
                        error={error}
                        className={clsx(
                            classes.suiInput,
                            classes.suiInputIcon_input,
                            {
                                [classes.suiInput_error]: error,
                                [classes.suiInput_success]: success,
                                [classes[`suiInput_${size}`]]: size,
                            }
                        )}
                        classes={{
                            focused: classes.suiInput_focused,
                            disabled: classes.suiInput_disabled,
                            error: classes.suiInput_error,
                            multiline: classes.suiInput_multiline,
                        }}
                    />
                </SuiBox>
            );
        } else if (withIcon.icon && withIcon.direction === "right") {
            template = (
                <SuiBox customClass={clsx(classes.suiInputIcon, customClass)}>
                    <InputBase
                        inputRef={ref}
                        {...rest}
                        disabled={disabled}
                        className={clsx(
                            classes.suiInput,
                            classes.suiInputIcon_input,
                            {
                                [classes.suiInput_error]: error,
                                [classes.suiInput_success]: success,
                                [classes[`suiInput_${size}`]]: size,
                            }
                        )}
                        classes={{
                            focused: classes.suiInput_focused,
                            disabled: classes.suiInput_disabled,
                            error: classes.suiInput_error,
                            multiline: classes.suiInput_multiline,
                        }}
                    />
                    <SuiBox customClass={classes.suiInputIcon_right}>
                        <Icon
                            className={classes.suiInputIcon_icon}
                            fontSize='small'
                            color={withIcon.color}>
                            {withIcon.icon}
                        </Icon>
                    </SuiBox>
                </SuiBox>
            );
        } else {
            template = (
                <InputBase
                    {...rest}
                    disabled={disabled}
                    className={clsx(classes.suiInput, customClass, {
                        [classes.suiInput_error]: error,
                        [classes.suiInput_success]: success,
                        [classes[`suiInput_${size}`]]: size,
                    })}
                    classes={{
                        focused: classes.suiInput_focused,
                        disabled: classes.suiInput_disabled,
                        error: classes.suiInput_error,
                        multiline: classes.suiInput_multiline,
                    }}
                />
            );
        }

        return template;
    }
);

// Setting default values for the props of SuiInput
SuiInput.defaultProps = {
    size: "medium",
    withIcon: {
        icon: false,
        direction: "none",
        component: "",
    },
    error: false,
    success: false,
    customClass: "",
    disabled: false,
};

// Typechecking props for the SuiInput
SuiInput.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    withIcon: PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
        direction: PropTypes.oneOf(["none", "left", "right"]),
    }),
    error: PropTypes.bool,
    success: PropTypes.bool,
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
};

export default SuiInput;
