import React from "react";
import { Icon, IconButton } from "components/toolkit";
export const IconComponent = ({ label, handleClick }) => {
  return (
    <IconButton color="inherit" size="small" onClick={handleClick}>
      <Icon>{label}</Icon>
    </IconButton>
  );
};

export const IconFunctionComponent = ({ label, id, handleClick }) => {
  return (
    <IconButton color="secondary" size="small" onClick={() => handleClick(id)}>
      <Icon>{label}</Icon>
    </IconButton>
  );
};

export const DeleteIconComponent = ({ handleClick }) => {
  return (
    <Icon
      className="font-bold text-secondary cursor-pointer"
      fontSize="small"
      aria-label="more"
      id="long-button"
      aria-controls="long-menu"
      // aria-expanded={open ? "true" : undefined}
      aria-haspopup="true"
      // onClick={handleClick}
      onClick={handleClick}
    >
      {/* more_vert */}
      delete
    </Icon>
  );
};
