import React, { useEffect } from "react";
import "./App.css";
import { remoteConfig } from "./firebase/index";
import { useDispatch, useSelector } from "react-redux";
import {
    setLabels,
    setButtonLabels,
    setTextLabels,
    setTableLabels,
} from "./redux/rookie/labels/LabelsActions";
import {
    getCompanyBasicDetails,
    getCompanyDetails,
} from "./redux/company-details/CompanyDetailActions";
import {
    setFunnelButtonLabels,
    setFunnelLabels,
    setFunnelTableLabels,
    setFunnelTextLabels,
} from "./funnel/redux/funnel-labels/FunnelLabelsActions";

import AuthRoutes from "routes/AuthRoutes";
import UnAuthRoutes from "routes/UnAuthRoutes";
import { userSelector } from "selectors/user";

import Logo from "./assets/images/LogoFavicon.png";
import ErrorBoundary from "components/error-boundary/ErrorBoundary";
import { useLocation } from "react-router-dom";
import { setCurrentUser } from "redux/user/UserActions";
import { companyId } from "utils/constants";
import { generalPOSTCall } from "pages/Tymeline/tymelineActions/tymelineActions";
import { START_USER_SURVEY } from "services/crudApiConfig";
import { setSurveysListData } from "redux/userdashboard/UserDashboardAction";
import { setSurveyButton } from "redux/userdashboard/UserDashboardAction";

// eslint-disable no-unused-vars
import monitoring from "./datadog/monitoring";
// eslint-disable no-unused-vars
import logger from "./datadog/logger";

/**
 * Base Component for for the entire app.
 * onlooad- updateRemoteConfigData();
 * Routers for entire dashboard are loaded here.
 */

function App() {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.company.basic_details);
    const state = useSelector(userSelector);

    // CODE: Might Review Later (For setting user using the token from link)
    const location = useLocation();
    useEffect(() => {
        const { pathname } = location;
        const urlKey = pathname.split("/").slice(1)[1];
        if (urlKey === "company-verification-request") {
            const businessId = pathname.split("/").slice(1)[2];
            if (businessId && businessId.trim()[0] === "B") {
                // Set company here using token but setting user here (temporary)
                dispatch(
                    setCurrentUser({
                        token: pathname.split("/").slice(1)[3],
                        account_id: "102-47-6692-R",
                        is_superuser: true,
                        primary: "#ff5733ff",
                        secondary: "#ff613f",
                        logo: {
                            logo: "https://firebasestorage.googleapis.com/v0/b/talkona.appspot.com/o/images%2F1612521126531.jpg?alt=media&token=23f6ab9b-0865-4235-abc7-a1dccaba3ee5",
                            name: "1612521126531.jpg",
                        },
                        [companyId]: "B-126-264-3729-S",
                    })
                );
            }
        }
    }, [location, dispatch]);

    useEffect(() => {
        dispatch(getCompanyBasicDetails());
        if (state !== null) {
            dispatch(getCompanyDetails());
            getUserSurveys();
        }
        remoteConfig
            .fetchAndActivate()
            .then((val) => {
                // ...
                const rookieLabels = remoteConfig.getValue(
                    "rookie_admin_labels"
                );
                const rookieButtonLabels = remoteConfig.getValue(
                    "rookie_admin_button_labels"
                );
                const rookieTextLabels = remoteConfig.getValue(
                    "rookie_admin_text_labels"
                );
                const rookieTableLabels = remoteConfig.getValue(
                    "rookie_admin_table_labels"
                );

                const funnelLabels = remoteConfig.getValue(
                    "funnel_admin_labels"
                );
                const funnelButtonLabels = remoteConfig.getValue(
                    "funnel_admin_button_labels"
                );
                const funnelTextLabels = remoteConfig.getValue(
                    "funnel_admin_text_labels"
                );
                const funnelTableLabels = remoteConfig.getValue(
                    "funnel_admin_table_labels"
                );

                dispatch(setLabels(JSON.parse(rookieLabels._value)));
                dispatch(
                    setButtonLabels(JSON.parse(rookieButtonLabels._value))
                );
                dispatch(setTextLabels(JSON.parse(rookieTextLabels._value)));
                dispatch(setTableLabels(JSON.parse(rookieTableLabels._value)));

                dispatch(setFunnelLabels(JSON.parse(funnelLabels._value)));
                dispatch(
                    setFunnelButtonLabels(JSON.parse(funnelButtonLabels._value))
                );
                dispatch(
                    setFunnelTextLabels(JSON.parse(funnelTextLabels._value))
                );
                dispatch(
                    setFunnelTableLabels(JSON.parse(funnelTableLabels._value))
                );

                // console.log();
            })
            .catch((err) => {
                // ...
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeFavicon = (link) => {
        let $favicon = document.querySelector('link[rel="icon"]');
        // If a <link rel="icon"> element already exists,
        // change its href to the given link.
        if ($favicon !== null) {
            $favicon.href = link;
            // Otherwise, create a new element and append it to <head>.
        } else {
            $favicon = document.createElement("link");
            $favicon.rel = "icon";
            $favicon.href = link;
            document.head.appendChild($favicon);
        }
    };

    const getUserSurveys = async (tymelineId) => {
        const payload = {
            "tymeline-id": tymelineId,
        };
        const response = await generalPOSTCall(payload, START_USER_SURVEY);
        if (response.status === 200) {
            const surveysList = response.data;
            dispatch(setSurveysListData(surveysList));
            dispatch(setSurveyButton(true));
        } else {
        }
    };
    if (company !== null) {
        document.getElementById("app").style.fontFamily = company.fonts;
        document.getElementById("app").style.color = "#626262";
        changeFavicon(Logo);
        // changeFavicon(company && company?.logo && company?.logo?.logo);
    }

    return (
        <div className='App'>
            <ErrorBoundary>
                <UnAuthRoutes />
                {/* AuthRoutes should be brought under /auth route */}
                <AuthRoutes />
            </ErrorBoundary>
        </div>
    );
}

export default App;
