// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
// import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/toolkit/SuiBox";
// import { ListItemIcon } from "components";

// Custom styles for the SidenavItem
import {
  item,
  itemContent,
  // itemArrow,
  itemIcon,
} from "components/toolkit/Sidenav/styles/sidenavItem";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";
import { useSelector } from "react-redux";
import { userSelector } from "selectors/user";
import { Box, Tooltip } from "@mui/material";

function LockedSidenavItem({
  name,
  active,
  nested,
  children,
  className,
  open,
  icon,
  ...rest
}) {
  const state = useSelector(userSelector);
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;

  return (
    <Tooltip
      title="You dont have the permission to view this section"
      placement="top"
    >
      <ListItem className={className} {...rest} component="li" sx={item}>
        <SuiBox
          sx={(theme) =>
            itemContent(theme, { active, miniSidenav, name, nested })
          }
        >
          {icon && (
            <span>
              <Box sx={(theme) => itemIcon(theme, { state })}>{icon}</Box>
            </span>
          )}
          <ListItemText primary={name} style={{ whiteSpace: "initial" }} />
          <Icon
            component="i"
            sx={{ fontSize: "1rem !important", color: "#777" }}
          >
            lock
          </Icon>
        </SuiBox>
      </ListItem>
    </Tooltip>
  );
}

// Setting default values for the props of LockedSidenavItem
LockedSidenavItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the LockedSidenavItem
LockedSidenavItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default LockedSidenavItem;
