import { ActualData } from "components/frequently-used-components/mini-components";
import React from "react";
import { useHistory } from "react-router-dom";

const ErrorTemplate = () => {
    const history = useHistory();
    const navigateToDashboard = () => {
        history.push("/user-dashboard");
    };
    return (
        <div className='error-container'>
            {/* <div className="compcontainer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.5 74.769">
          <path fill="#C7CCDB" d="M58.073 74.769H32.426l6.412-19.236h12.824z" />
          <path
            fill="#373F45"
            d="M90.5 52.063c0 1.917-2.025 3.471-4.525 3.471H4.525C2.025 55.534 0 53.98 0 52.063V3.471C0 1.554 2.026 0 4.525 0h81.449c2.5 0 4.525 1.554 4.525 3.471v48.592z"
          />
          <path
            fill="#F1F2F2"
            d="M84.586 46.889c0 1.509-1.762 2.731-3.936 2.731H9.846c-2.172 0-3.933-1.223-3.933-2.731V8.646c0-1.508 1.761-2.732 3.933-2.732H80.65c2.174 0 3.936 1.225 3.936 2.732v38.243z"
          />
          <path
            fill="#A2A7A5"
            d="M16.426 5.913L8.051 23h13l-6.875 12.384L26.75 46.259l-8.375-11.375L26.75 20H14.625l6.801-14.087zM68.551 49.62l-8.375-17.087h13l-6.875-12.384L78.875 9.274 70.5 20.649l8.375 14.884H66.75l6.801 14.087z"
          />
        </svg>
      </div> */}
            <h1 className='header'>Oops!</h1>
            <div className='instructions'>
                <h3>Something went wrong at our end.</h3>
                <h6>
                    We have noted the problem and will fix as soon as possible.
                </h6>
                {/* <ActualData
                    data=' Sorry, something went wrong on our end. We are currently trying to fix
          the problem.'
                    color={"error"}
                /> */}
                {/* <p></p> */}
                <h6>In the meantime, you can</h6>
                <div className='step'>
                    <svg
                        className='icon'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 60 60'
                        style={{ marginRight: "0.5rem" }}>
                        <path d='M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30   S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z' />
                        <path d='M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z' />
                    </svg>
                    <div
                        className='cursor-pointer'
                        onClick={navigateToDashboard}>
                        <ActualData
                            fontSize='1rem'
                            styleProps={{
                                textDecoration: "underline !important",
                            }}
                            data='Return to previous screen'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorTemplate;
