import React from "react";
import { withRouter } from "react-router-dom";
import "./error-template-style.css";
import ErrorTemplate from "./ErrorTemplate";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        const { history } = this.props;

        history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        const ErrorComponent = this.props.errorComponent ?? ErrorTemplate;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorComponent />;
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
