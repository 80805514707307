import axios from "axios";
import { CHECK_COMPANY } from "services/crudApiConfig";
import { TYMELINE_SUMMARY_REGENERATE } from "services/crudApiConfig";
import { TYMELINE_TEAM_COMPATABILITY } from "services/crudApiConfig";

import { GROWTH_RECOMMENDATION } from "services/crudApiConfig";
import { UPDATE_ENDORSED_SKILLS_REQUESTS } from "services/crudApiConfig";
import {
    GET_ENDORSED_SKILLS_REQUESTS,
    POST_REQUEST_ENDORSED_SKILLS,
    SELECTED_SKILLS_CHART,
    UNLOCK_CANDIDATE_GOVT_ID,
    POST_INTEGRATION_AUTH_KEY,
    CHECK_CANDIDATE_GOVT_ID,
    CHECK_IS_CANDIDATE_CRIMINAL,
    CHECK_CANDIDATE_HEALTH_RECORD,
    UNLOCK_CANDIDATE_CRIMINAL_CHECK,
    UNLOCK_CANDIDATE_HEALTH_CHECK,
    TYMELINE_COMPARE,
    TYMELINE_SUGGESTED_TESTS,
    TYMELINE_BILLING_ENDPOINT,
    TYMELINE_SUMMARY,
    EMPLOYEE_SUMMARY,
    GRAPH_RATE_CHART,
    TYMELINE_ANOMALIES,
    TYMELINE_QUESTIONS,
    PEOPLE_SEARCH_RESULTS,
    TYMELINE_SCORE,
    VALIDATERECEIVER,
    CONSENTS,
    VERIFYOTP,
    getEndpointConfig,
    TYMELINE_VEC,
    POST_SELECTED_KILLS,
} from "services/crudApiConfig";

export const generateConsenst = async (userData, state) => {
    const config = {
        headers: {
            Authorization: state.token,
        },
    };
    userData.businessId = state.businessId;
    const endpointConfig = getEndpointConfig(CONSENTS);
    const url = endpointConfig.post;
    return axios
        .post(url, userData, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const verifyOtp = async (data, state) => {
    const config = {
        headers: {
            Authorization: state.token,
        },
    };
    const endpointConfig = getEndpointConfig(VERIFYOTP);
    const url = endpointConfig.post;
    return axios
        .post(url, data, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const postAuthKey = async (userData, state) => {
    const config = {
        headers: {
            Authorization: state.token,
        },
    };
    userData.businessId = state.businessId;
    const endpointConfig = getEndpointConfig(POST_INTEGRATION_AUTH_KEY);
    const url = endpointConfig.post;
    return axios
        .post(url, userData, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
export const deleteIntegration = async (integrationId) => {
    const endpointConfig = getEndpointConfig(POST_INTEGRATION_AUTH_KEY);
    const url = endpointConfig.delete.replace(
        "{integration_id}",
        integrationId
    );
    return axios
        .delete(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
export const validateReceiver = async (data, state) => {
    const config = {
        headers: {
            Authorization: state.token,
        },
    };
    const endpointConfig = getEndpointConfig(VALIDATERECEIVER);
    const url = endpointConfig.post;
    return axios
        .post(url, data, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};
export const submitBillingDetails = async (data) => {
    const endpointConfig = getEndpointConfig(TYMELINE_BILLING_ENDPOINT);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};
export const checkCandidateHealthRecord = async (data) => {
    const endpointConfig = getEndpointConfig(CHECK_CANDIDATE_HEALTH_RECORD);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const checkIsCandidateCriminal = async (data) => {
    const endpointConfig = getEndpointConfig(CHECK_IS_CANDIDATE_CRIMINAL);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const unlockCandidateCriminalCheck = async (data) => {
    const endpointConfig = getEndpointConfig(UNLOCK_CANDIDATE_CRIMINAL_CHECK);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const unlockCandidateHealthCheck = async (data) => {
    const endpointConfig = getEndpointConfig(UNLOCK_CANDIDATE_HEALTH_CHECK);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getTymelineSummary = async (accountId, state) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_SUMMARY);
    const url = endpointConfig.get.replace("{account_id}", accountId);
    return axios
        .get(url, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const regenerateTymelineSummary = async (user) => {
    const config = { headers: { Authorization: user.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_SUMMARY_REGENERATE);
    const url = endpointConfig.post;
    return axios
        .post(url, {}, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getEmployeeSummary = async (accountId, state) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(EMPLOYEE_SUMMARY);
    const url = endpointConfig.get.replace("{team_member_id}", accountId);
    return axios
        .get(url, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const postTymelineVec = async (data, state) => {
    // const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_VEC);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getPeopleSearchResults = async (data, state) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(PEOPLE_SEARCH_RESULTS);
    const url = endpointConfig.post;
    return axios
        .post(url, data, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getTymelineScore = async (data, state) => {
    // const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_SCORE);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getGraphSummaryData = async (accountId, state) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(GRAPH_RATE_CHART);
    const url = endpointConfig.get.replace("{account_id}", accountId);
    return axios
        .get(url, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};
export const tymelineRoleCompare = async (data, state) => {
    // const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_COMPARE);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const tymelineTeamCompatibility = async (
    teamId,
    teamMemberId,
    state
) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_TEAM_COMPATABILITY);
    const url = endpointConfig.post
        .replace("{team_id}", teamId)
        .replace("{team_member_id}", teamMemberId);
    return axios
        .post(url, {}, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const tymelineAnomalies = async (accountId, state) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_ANOMALIES);
    const url = endpointConfig.get.replace("{team_member_id}", accountId);
    return axios
        .get(url, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const userGrowthRecommendations = async (accountId, state) => {
    const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(GROWTH_RECOMMENDATION);
    const url = endpointConfig.get.replace("{account_id}", accountId);
    return axios
        .get(url, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const tymelineSuggestedTests = async (data, state) => {
    // const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_SUGGESTED_TESTS);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};
export const tymelineQuestions = async (data, state) => {
    // const config = { headers: { Authorization: state.token } };
    const endpointConfig = getEndpointConfig(TYMELINE_QUESTIONS);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const checkGovtIdRecord = async (data) => {
    const endpointConfig = getEndpointConfig(CHECK_CANDIDATE_GOVT_ID);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const unlockGovtIdCheck = async (data) => {
    const endpointConfig = getEndpointConfig(UNLOCK_CANDIDATE_GOVT_ID);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const postEndorsedSkills = async (data) => {
    const endpointConfig = getEndpointConfig(POST_SELECTED_KILLS);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getEndorsedSkills = async (data) => {
    const endpointConfig = getEndpointConfig(SELECTED_SKILLS_CHART);
    const url = endpointConfig.post;
    const payload = {
        "tymeline-id": data,
        "pass-code": "077213ea93b21b56aeb437d220ee83a6",
    };
    return axios
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const postRequestEndorsedSkills = async (data) => {
    const endpointConfig = getEndpointConfig(POST_REQUEST_ENDORSED_SKILLS);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getSkillEndorsementRequests = async (data) => {
    const endpointConfig = getEndpointConfig(GET_ENDORSED_SKILLS_REQUESTS);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const updateSkillEndorsementRequests = async (data) => {
    const endpointConfig = getEndpointConfig(UPDATE_ENDORSED_SKILLS_REQUESTS);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getGrowthRecommondationData = async (data) => {
    const endpointConfig = getEndpointConfig(GROWTH_RECOMMENDATION);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const validateDomain = async (data) => {
    const endpointConfig = getEndpointConfig(CHECK_COMPANY);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const generalPOSTCall = async (data, endpointConstant) => {
    const endpointConfig = getEndpointConfig(endpointConstant);
    const url = endpointConfig.post;
    return axios
        .post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};
