import clsx from "clsx";

// @mui material components
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
// import Link from "@mui/material/Link"
import Icon from "@mui/material/Icon";
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";
// import SuiButton from "components/toolkit/SuiButton";

// Custom styles for the Configurator
import styles from "components/toolkit/surveys/styles";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";
import SliderComponent from "components/required-components/slider-component/SliderComponent";
import { BadgeContent } from "components/frequently-used-components/mini-components";

function SurveySection({ surveysList }) {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, sidenavColor } = controller;
  // const [disabled, setDisabled] = useState(false);
  const classes = styles({ sidenavColor });

  const handleCloseConfigurator = () => {
    dispatch({ type: "OPEN_CONFIGURATOR", value: false });
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.configurator, {
          [classes.configurator_open]: openConfigurator,
          [classes.configurator_close]: !openConfigurator,
        }),
      }}
    >
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox display="flex">
          <SuiTypography variant="h6" mr={1}>
            Your input matters
          </SuiTypography>
          <BadgeContent
            badgeLabel="coming soon..!"
            color="error"
            fontSize="0.5rem"
          />
        </SuiBox>

        <Icon
          className={`font-bold ${classes.configurator_close_icon}`}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SliderComponent surveysList={surveysList} />
      </SuiBox>

      <Divider />
    </Drawer>
  );
}

export default SurveySection;
