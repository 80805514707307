const INITIAL_STATE = {
  dashboardData: null,
  skillsData: null,
  graphData: null,
  tymelineScore: null,
  show: false,
  showSurveyButton: true,
  growthRecommendationData: {},
  surveysListData: [],
};
const UserDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_DASHBOARD_DATA":
      return {
        ...state,
        dashboardData: action.payload,
      };
    case "SET_SKILLS_OVERVIEW":
      return {
        ...state,
        skillsData: action.payload ?? [],
      };
    case "SET_TYMELINE_SCORE":
      return {
        ...state,
        tymelineScore: action.payload ?? [],
      };
    case "SET_GROWTH_OVERVIEW":
      return {
        ...state,
        graphData: action.payload ?? [],
      };
    case "SET_SURVEYS_LIST_DATA":
      return {
        ...state,
        surveysListData: action.payload ?? [],
      };
    case "SHOW_SURVEY_BUTTON":
      return {
        ...state,
        showSurveyButton: action.payload ?? false,
      };
    case "SET_GROWTH_RECOMMENDATION_DATA":
      return {
        ...state,
        growthRecommendationData: action.payload ?? {},
      };
    default:
      return state;
  }
};

export default UserDashboardReducer;
